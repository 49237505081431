/* eslint-disable no-template-curly-in-string */
const ptBr = {
  LOCAL_LOGIN: {
    TITLE: 'Acesso restrito',
    SUBTITLE: 'Faça seu login abaixo',
    FIELD_USERNAME: 'Usuário',
    FIELD_PASSWORD: 'Senha',
    BTN_FORM_LOGIN: 'Acessar',
    BTN_HOME: 'Ir para página inicial'
  },
  TEST: {
    TITLE: 'Título do teste',
    SUBTITLE: 'Subtítulo de teste',
    PARAGRAPH: 'Este é um longo parágrafo de teste que está sendo colocado aqui. ',
    HOME: {
      TITLE: 'Página inicial',
      SUBTITLE: 'Esta é a página inicial',
      BTN_LOGIN: 'Ir para login'
    }
  },
  COMMONS: {
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    UPDATE: 'Atualizar',
    REGISTER: 'Inserir',
    CONFIRM: 'Confirmar',
    FILTERS: 'Filtros',
    ADD: 'Adicionar',
    NO_OPTIONS: 'Nenhuma opção válida'
  },
  ROLES: {
    ADMIN: 'Administrador',
    OPERATOR: 'Operador'
  },
  STATIC_ATTRIBUTE: {
    FIRST_NAME: 'Primeiro nome',
    LAST_NAME: 'Sobrenome',
    DATE_OF_BIRTH: 'Data de nascimento',
    IDP_ID: 'Identificador do IDP',
    CPF: 'CPF',
    NAME_COMPOSITION: 'Composição do nome civil',
    CN: 'Nome comum',
    EMAIL: 'E-mail',
    EDU_AFFILIATION_TYPE: 'Tipo de afiliação educacional',
    EDU_PERSON_PRINCIPAL_NAME: 'EPPN',
    SOCIAL_NAME: 'Nome social',
    SOCIAL_NAME_COMPOSITION: 'Composição do nome social',
    RG: 'RG',
    NIS: 'NIS',
    INSS: 'INSS',
    TITULO_ELEITOR: 'Título de eleitor'
  },
  VALIDATION: {
    REQUIRED_FIELD: 'Campo obrigatório',
    STRING_LENGTH: 'Deve ter {{length}} caracteres',
    AT_LEAST_VALUE: 'Dever ser ao menos {{min}}',
    INVALID_EMAIL: 'E-mail inválido',
    INVALID_URL: 'Endereço web inválido',
    MAX_LENGTH: 'Deve ter no máximo {{max}} caracteres',
    INVALID_CNPJ: 'CNPJ inválido',
    INVALID_CPF: 'CPF inválido',
  },
  ERROR: {
    DEFAULT_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    TOKEN_INVALID: 'Token inválido ou expirado',
    TOKEN_EXPIRED: 'O token expirou',
    USER_ALREADY_EXISTS_EMAIL: 'E-mail já cadastrado no sistema.',
    USER_ALREADY_EXISTS_CPF: 'O CPF informado possui cadastro no sistema.',
    USER_NOT_LOGGED: 'Usuário não autenticado',
    CAPTCHA_INVALID: 'Erro ao validar ReCAPTCHA',
    TOKEN_ALREADY_CONSUMED: 'Token já utilizado',
    NotAuthorizedException: 'Usuário ou senha inválidos',
    BlockedException: 'Bloqueado!',
    USER_NOT_FOUND: 'Usuário não autorizado a acessar esta aplicação',
    400: 'Não foi possível concluir a requisição',
    401: 'Usuário não autorizado',
    500: 'Ocorreu um erro inesperado',
    'Network Error': 'Ocorreu um erro de rede',
  },
  NOTIFICATIONS: {
    CREATED_SUCCESS: 'Registro incluído com sucesso.',
    UPDATED_SUCCESS: 'Registro alterado com sucesso.',
    DELETED_SUCCESS: 'Registro excluído com sucesso.',
    DEFAULT_ERROR: 'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
  },
  CELL_ACTIONS: {
    EDIT: 'Editar',
    DEACTIVATE: 'Desativar',
    RESET_PASSWORD: 'Resetar a senha',
  },
  STATUS: {
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
  },
  COPIED_POPUP: {
    COPIED: 'Copiado',
  },
  PROFILE: {
    TITLE: 'Perfil',
    LOGOUT: 'Sair',
  },
  LOGIN: {
    WELCOME_TITLE: 'Seja bem-vindo!',
    WELCOME_MESSAGE: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent condimentum volutpat arcu ut vestibulum. Mauris eget risus et mauris semper bibendum et sed elit. Aenean malesuada semper ante tristique luctus. Fusce quis ligula sit amet ex vulputate elementum vitae eu lectus. Curabitur consequat mi in quam sagittis lacinia. Phasellus mattis feugiat fermentum. Aliquam nec orci lorem.
    Ut vel nisi dignissim, laoreet massa at, elementum tortor. Etiam lobortis sapien risus, in ullamcorper lectus imperdiet egestas. Vestibulum ultricies justo eu tellus placerat placerat. Aliquam id eros massa. Fusce pellentesque dui ac purus semper sodales. Nulla facilisi. Etiam sit amet accumsan nibh. Morbi nisl nibh, volutpat in placerat sed, tincidunt eu magna. In hac habitasse platea dictumst.`,
    LOGIN_TITLE: 'Login',
    USER: 'Usuário',
    USER_PLACEHOLDER: 'Insira seu CPF ou e-mail',
    PASSWORD: 'Senha',
    PASSWORD_PLACEHOLDER: 'Insira sua senha',
    FORGET_PASSWORD: 'Esqueci minha senha',
    SIGNIN: 'Entrar',
    PASSWORD_REQUIRED: 'Senha deve ser preenchida',
    EMAIL_OR_DOCUMENT_REQUIRED: 'Digite um CPF ou um e-mail válidos',
    PRIVACITY_POLICY:
      'Ao utilizar nossos serviços, você concorda com a nossa política de privacidade. <br />Para mais informações, <Link>clique aqui</Link>',
    PRIVACITY_POLICY_PROCEED: 'prosseguir',
  },
  TOKEN: {
    VALIDATION: 'Aguarde enquanto validamos o token...'
  },
  LOGOUT: {
    WAIT: 'Aguarde enquanto encerramos a sua sessão...'
  },
  CREATE_PASSWORD: {
    NEW_PASSWORD: 'Nova senha',
    FORM_FILLING: 'Preencha os dados do usuário:',
    DOCUMENT: 'CPF',
    PASSWORD: 'Senha',
    PASSWORD_PLACEHOLDER: 'Insira sua senha',
    REPEAT_PASSWORD: 'Repetir Senha',
    REPEAT_PASSWORD_PLACEHOLDER: 'Repita sua senha',
    SAVE: 'Salvar',
    PASSWORD_REQUIRED: 'Senha deve ser preenchida',
    CONFIRMATION_INVALID: 'Confirmação não confere com a senha informada',
    TERMS_CONFIRMATION_REQUIRED:
      'Para continuar é preciso aceitar os termos de uso',
    RECAPTCHA_REQUIRED: 'ReCaptcha deve ser informado',
  },
  PASSWORD_UPDATED: {
    PASSWORD_UPDATED: 'Senha atualizada com sucesso!',
    LOGIN: 'Fazer login',
  },
  PASSWORD_VALIDATION: {
    VALIDATION_MUST_HAVE_LOWERCASE: 'Deve conter ao menos uma letra minúscula',
    VALIDATION_MUST_HAVE_UPPERCASE: 'Deve conter ao menos uma letra maiúscula',
    VALIDATION_MUST_HAVE_NUMBER: 'Deve conter ao menos um número',
    VALIDATION_MUST_HAVE_SPECIAL_CHARACTER:
      'Deve conter ao menos um caractere especial',
    VALIDATION_MUST_HAVE_MIN_LENGTH: 'Deve conter ao menos 11 caracteres',
    VALIDATION_MUST_BE_UNPRECEDENTED:
      'Não pode ser igual às últimas 5 senhas utilizadas anteriormente',
  },
  NO_ACCESS: {
    ACCESS_NOT_ALLOWED: 'Você não tem permissão para acessar esta aplicação!',
    ACCESS_HOME_PAGE: 'Ir para a página inicial',
    USER_OPTIONS: 'O que você pode fazer agora:',
  },
  PRIVATE_FOOTER: {
    PRIVACITY_POLICY:
      'Texto destinado a exibição das informações relacionadas à licença de uso.',
  },
  USERS: {
    TITLE: 'Usuários',
    DIALOG_PASSWORD_TITLE: 'Resetar senha',
    DIALOG_PASSWORD_MESSAGE:
      'Tem certeza que deseja limpar as credenciais de acesso deste usuário?',
    TABLE_HEADER_NAME: 'Nome do usuário',
    TABLE_HEADER_CPF: 'CPF',
    TABLE_HEADER_ROLE: 'Perfil',
    TABLE_HEADER_STATUS: 'Status',
    TABLE_HEADER_ACTIONS: 'Ações',
    SEARCH_PLACEHOLDER: 'Pesquise pelo Nome do Usuário ou CPF',
    CREATE_USER: 'Cadastrar Usuário',
  },
  USER_FORM: {
    NAME: 'Nome',
    PLACEHOLDER_NAME: 'Digite o nome do usuário',
    EMAIL: 'E-mail',
    IDENTITY_PROVIDER: 'Instituição',
    PLACEHOLDER_EMAIL: 'Digite o e-mail do usuário',
    CONFIRM_EMAIL: 'Confirmar e-mail',
    PLACEHOLDER_CONFIRM_EMAIL: 'Digite novamente o e-mail do usuário',
    SUCCESS: 'Solicitação enviada com sucesso',
    EMAIL_ALREADY_EXISTS: 'Endereço de e-mail já cadastrado.',
    ERROR_LIMIT_OPERATORS: 'Instituição atingiu o limite de operadores',
    ERROR_SECURITY_CODE: 'Código de segurança inválido',
    ERROR_GENERIC_OPERATOR: 'Ocorreu um erro ao criar o operador',
    ERROR_GENERIC_ADMIN: 'Ocorreu um erro ao criar o administrador',
    ERROR_GENERIC: 'Ocorreu um erro ao criar o usuário'
  },
  NEW_OPERATOR: {
    TITLE: 'Novo Operador',
    DESCRIPTION: 'Insira os dados do novo operador para iniciar o procedimento de ativação.'
  },
  NEW_ADMIN: {
    TITLE: 'Novo Administrador',
    DESCRIPTION: 'Insira os dados do novo administrador para iniciar o procedimento de ativação.'
  },
  REQUEST_PASSWORD_RESET: {
    WELCOME_TITLE: 'Seja bem-vindo!',
    WELCOME_MESSAGE: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent condimentum volutpat arcu ut vestibulum. Mauris eget risus et mauris semper bibendum et sed elit. Aenean malesuada semper ante tristique luctus. Fusce quis ligula sit amet ex vulputate elementum vitae eu lectus. Curabitur consequat mi in quam sagittis lacinia. Phasellus mattis feugiat fermentum. Aliquam nec orci lorem.
    Ut vel nisi dignissim, laoreet massa at, elementum tortor. Etiam lobortis sapien risus, in ullamcorper lectus imperdiet egestas. Vestibulum ultricies justo eu tellus placerat placerat. Aliquam id eros massa. Fusce pellentesque dui ac purus semper sodales. Nulla facilisi. Etiam sit amet accumsan nibh. Morbi nisl nibh, volutpat in placerat sed, tincidunt eu magna. In hac habitasse platea dictumst.`,
    TITLE: 'Recuperação de Senha',
    USER: 'Usuário',
    USER_PLACEHOLDER: 'Insira seu CPF ou e-mail',
    CONFIRM: 'Confirmar',
    EMAIL_OR_DOCUMENT_REQUIRED: 'Digite um CPF ou um e-mail válidos',
    EMAIL_SENT: 'E-mail enviado!',
    EMAIL_SENT_CONFIRMATION:
      'Foi enviado um e-mail para sua conta pessoal. Siga as instruções para recuperar sua senha.',
    GOT_IT: 'Entendi',
  },
  FORM_LOADING: {
    LOADING: 'Carregando...',
  },
  MFA_SETUP: {
    CONFIGURE_MFA: 'Configurar Código de Verificação',
    CONFIRM_CODE: 'Enviar Código',
    DOWNLOAD_AUTHENTICATOR:
      'Baixe o aplicativo de autenticação em seu celular para escanear o código QR:',
    COPY_SECRET_1: 'Ou',
    COPY_SECRET_2: 'clique aqui',
    COPY_SECRET_3:
      'para copiar o código de autenticação e utilizar em um aplicativo em seu computador.',
    DOWNLOAD_ON_GOOGLE_PLAY: 'Baixar Google Authenticator para Android',
    DOWNLOAD_ON_APP_STORE: 'Baixar Google Authenticator para iOS',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    TOKEN_EXCEPTION:
      'Código inválido. Tente um novo código ou cadastre o QRCode novamente.',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.',
  },
  MFA_TOKEN: {
    MFA: 'Código de Verificação',
    DESCRIPTION:
      'Busque o código de 6 digitos que será apresentado no aplicativo de autenticação na qual você vinculou o QR Code na etapara de cadastro do sistema',
    CONFIRM_CODE: 'Enviar Código',
    TOKEN_EXCEPTION: 'Código inválido. Tente um novo código',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.',
  },
  SELECT_SECURITY_QUESTIONS: {
    SAVE: 'Salvar',
    ANSWER: 'Resposta',
    ANSWER_PLACEHOLDER: 'Resposta:',
    SELECT_QUESTIONS_TITLE:
      'Escolha e responda as duas perguntas abaixo para prosseguir:',
    SELECT_QUESTIONS: 'Selecione uma pergunta...',
    SECURITY_QUESTIONS: 'Perguntas e respostas',
    QUESTION_REQUIRED: 'Pergunta deve ser selecionada',
    ANSWER_REQUIRED: 'Resposta deve ser informada',
    FIRST_QUESTION: 'Pergunta 1',
    SECOND_QUESTION: 'Pergunta 2',
    HINT: 'Dica: ',
    HINT_TEXT:
      'Escolha perguntas que possa lembrar facilmente a resposta, pois esse processo será crucial para recuperação de senha no futuro.',
  },
  RESET_PASSWORD: {
    ANSWER: 'Resposta:',
    ANSWER_REQUIRED: 'Resposta da pergunta deve ser preenchida',
    QUESTION_REQUIRED: 'Houve um erro ao buscar a pergunta de segurança',
    BACK_TO_HOME: 'Voltar para página inicial',
    INVALID_TOKEN: 'Seu token é inválido ou expirou.',
    LINK_EMAIL: 'Informe seu e-mail ',
    TEXT_TOKEN: 'para gerar um novo token <br />ou volte para a ',
    LINK_INICIAL: 'página inicial',
  },
  LIST: {
    NONE_RESULTS: 'Nenhum registro encontrado',
  },
  CONFIGURATIONS_LIST: {
    TITLE: 'Configurações',
    FILTER_TITLE: 'Filtros',
    FILTER_BY_NAME_PLACEHOLDER: 'Nome da configuração',
    SUB_TITLE: 'Lista de configurações',
    TABLE_HEADER_NAME: 'Nome',
    TABLE_HEADER_VALUE: 'Valor',
    TABLE_HEADER_ACTIONS: 'Ações',
    CAFE_IDPs_METADATA_URL: 'URL do XML de metadados dos IDPs cadastrados no sistema CAFE',
    CRON_EXPRESSION_CLEAN_REVOKED_TOKENS_JOB: 'Expressão cron para execução da limpeza de tokens revogados na base de dados',
    CRON_EXPRESSION_FETCH_IDPS_JOB: 'Expressão cron para execução da atualização dos IDPs cadastrados no sistema CAFE'
  },
  CONFIGURATION_EDIT: {
    TITLE: 'Editar configuração',
    SUB_TITLE: 'Edite o valor da configuração cadastrada',
    FIELD_ID: 'Identificador',
    FIELD_ID_PLACEHOLDER: 'Identificador da configuração',
    FIELD_NAME: 'Nome',
    FIELD_NAME_PLACEHOLDER: 'Nome da configuração',
    FIELD_VALUE: 'Valor',
    FIELD_VALUE_PLACEHOLDER: 'Valor da configuração',
    UPDATE_SUCCESS_MESSAGE: 'Configuração atualizada com sucesso',
    UPDATE_ERROR_MESSAGE: 'Erro ao atualizar a configuração'
  },
  FILTER: {
    FILTER_BY_IDP_LABEL: 'Buscar por instituição',
    FILTER_BY_NAME_LABEL: 'Nome',
    FILTER_BY_EMAIL_LABEL: 'E-mail',
    FILTER_BY_STATUS_LABEL: 'Status',
    FILTER_BY_STATUS_ACTIVE: 'Ativo',
    FILTER_BY_STATUS_INACTIVE: 'Inativo',
    FILTER_BY_STATUS_ALL: 'Todos',
    FILTER_BY_STATUS_PENDING: 'Aguardando primeira ativação',
    FILTER_BY_STATUS_UNKNOWN: 'Desconhecido',
    FILTER_BY_STATUS_PENDING_ACTIVATION: 'Aguardando ativação',
    DO_FILTERS: 'Aplicar filtros',
    CLEAN_FILTERS: 'Remover filtros',
  },
  OPERATOR_LIST: {
    TITLE: 'Operadores',
    DESCRIPTION: 'Cadastre um operador para\n' +
      '              permitir o mapeamento dos atributos de uma instituição. São permitidos até <strong>3\n' +
      '                operadores</strong> por instituição.',
    ADD_OPERATOR_TITLE: 'Adicionar operador',
    SUBMIT_ERROR: 'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    INACTIVE_SUCCESS: 'Operador inativado com sucesso!',
    ACTIVE_SUCCESS: 'Solicitação de ativação enviada ao operador!',
    LIMIT_OPERATORS_REACHED: 'Limite de operadores atingido para a instituição.'
  },
  ADMIN_LIST: {
    TITLE: 'Administradores',
    DESCRIPTION: 'Cadastre um administrador para\n' +
      '              permitir o gerenciamento de operadores na instituição.',
    ADD_ADMIN_TITLE: 'Adicionar administrador',
    SUBMIT_ERROR: 'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    INACTIVE_SUCCESS: 'Administrador inativado com sucesso!',
    ACTIVE_SUCCESS: 'Solicitação de ativação enviada ao administrador!'
  },
  TABLE: {
    EMPTY: 'Nenhum registro encontrado',
    ERROR: 'Não foi possível carregar os registros',
    TITLE_ADMINISTRATORS: 'Lista de administradores',
    TITLE_OPERATORS: 'Lista de operadores',
    ADMINISTRATORS_EMPTY: 'Nenhum administrador encontrado',
    OPERATORS_EMPTY: 'Nenhum operador encontrado',
    ADMINISTRATORS_ERROR: 'Não foi possível carregar os administradores',
    OPERATORS_ERROR: 'Não foi possível carregar os operadores',
    HEADER_NAME: 'Nome',
    HEADER_EMAIL: 'E-mail',
    HEADER_STATUS: 'Status',
    HEADER_INSTITUTION: 'Instituição',
    HEADER_ACTIONS: 'Ações',
    STATUS_ACTIVE: 'Ativo',
    STATUS_INACTIVE: 'Inativo',
    STATUS_PENDING_VALIDATION: 'Aguardando primeira ativação',
    STATUS_UNKNOWN: 'Desconhecido',
    STATUS_PENDING_ACTIVATION: 'Aguardando ativação',
  },
  API_KEY_LIST: {
    TITLE: 'Chaves de API',
    REMOVE_SUCCESS_MESSAGE: 'Chave de API removida com sucesso!',
    REMOVE_ERROR_MESSAGE: 'Não foi possível remover a chave de API. Caso o problema persista contate o administrador do sistema.',
    COPY_SUCCESS_MESSAGE: 'Chave de API copiada com sucesso para a área de transferência!',
    COPY_ERROR_MESSAGE: 'Não foi possível copiar a chave de API. Caso o problema persista contate o administrador do sistema.',
    TABLE_HEADER_APPLICATION_URL: 'URL da aplicação',
    TABLE_HEADER_DESCRIPTION: 'Descrição',
    TABLE_HEADER_ACTIONS: 'Ações',
    COPY_API_KEY_TITLE: 'Copiar chave de API',
    REMOVE_API_KEY_TITLE: 'Remover chave de API',
    REMOVE_MODAL_TITLE: 'Deseja excluir essa chave de API?',
    REMOVE_MODAL_BODY: 'Atenção, a exclusão da chave de API é uma ação irreversível!'
  },
  API_KEY_FORM: {
    REQUIRED_URL_MESSAGE: 'URL da aplicação deve ser preenchida',
    INVALID_URL_MESSAGE: 'O campo de URL não é uma url válida',
    SUBMIT_ERROR_MESSAGE: 'Erros encontrados, verifique os campos!',
    CREATE_SUCCESS_MESSAGE: 'Chave de API criada com sucesso, verifique a listagem!',
    CREATE_ERROR_MESSAGE: 'Ocorreu um erro ao criar a chave de API, tente novamente mais tarde!',
    APPLICATION_URL_LABEL: 'URL da aplicação*',
    APPLICATION_URL_PLACEHOLDER: 'Insira a URL da aplicação, ex: https://www.exemplo.com.br',
    DESCRIPTION_LABEL: 'Descrição',
    DESCRIPTION_PLACEHOLDER: 'Insira uma descrição',
  },
  USERS_VISUALIZATION: {
    INACTIVE_TITLE: 'Deseja inativar o usuário {{username}}?',
    INACTIVE_MESSAGE: 'Ao inativar o usuário {{username}}, o mesmo não poderá mais acessar a aplicação.',
    ACTIVE_TITLE: 'Deseja ativar o usuário {{username}}?',
    ACTIVE_MESSAGE: 'Confirme ativação do usuário {{username}}.',
    RESEND_ACTIVATION_EMAIL_SUCCESS: 'E-mail de ativação reenviado com sucesso!',
    DELETE_TITLE: 'Deseja excluir o usuário {{username}}?',
    DELETE_MESSAGE: 'Ao excluir o usuário {{username}}, o mesmo não poderá mais validar seu código de segurança para acessar a aplicação.',
    DELETE_SUCCESS: 'Usuário excluído com sucesso!'
  }
};

export default ptBr;
