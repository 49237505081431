/**
 * WARNING: This app uses React Router DOM v5, which is deprecated.
 * <br />
 * Consider switching to the latest v6 in the future.
 */
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { isArray } from 'lodash';
import Configurations from './pages/Login/components/Configurations/List/Configurations';
import Home from './pages/Home/Index';
import Login from './pages/Login/Login';
import MapAttributes from './pages/MapAttributes/Index';
import MyInstitution from './pages/MyInstitution/Index';
import Operators from './pages/UsersVisualization/components/Operators';
import NewOperator from './pages/NewIdpUser/NewOperator';
import { EditConfiguration } from './pages/Login/components/Configurations/Edit/EditConfiguration';
import { ApiCredentialPage } from './pages/ApiCredential/Index';
import Administrators from './pages/UsersVisualization/components/Administrators';
import ActivateUser from './pages/ActivateOperator/Index';
import CreateSecurityCodeCallback from './pages/CreateSecurityCodeCallback/Index';
import { CallBackPage } from './pages/Callback/Index';
import { useAuthentication, useRole } from './hooks';
import NewAdministrator from './pages/NewIdpUser/NewAdministrator';
import { MyAccount } from './pages/ResetSecurityCode/MyAccount';

interface PrivateRouteProps {
  authorized: boolean,
  basePath: string,
  children: ReactElement | ReactElement[]
}

/**
 * Wrapper component to use for route protection.
 * Its children must be a Route component.
 * <br />
 * You can provide an expression to allow or deny a path rendering.
 * @param authorized  A boolean expression flag that checks user path permission
 * @param basePath    The base URI. Every protected route will have its base path prefixed (e.g. "/<base-path>/<protected-route-path>".
 * @param children    The element (or elements) representing Route component(s).
 * @constructor
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ authorized, basePath, children }) => {
  const renderChildren = () => {
    if (isArray(children)) {
      return children.map((c, index) =>
        React.cloneElement(c, {
          ...c.props,
          key: `path-${index}`,
          path: `${basePath}${c.props.path}`,
        }),
      );
    }
    return React.cloneElement(children, { ...children.props, path: `${basePath}${children.props.path}` });
  };

  if (!authorized)
    return <Redirect to={'/'} />;

  return (
    <Switch>
      {renderChildren()}
    </Switch>
  );
};

const Routes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();
  const role = useRole();

  const renderRoutes = (): ReactElement[] => {
    if (role === 'MASTER') {
      return [
        <Route exact path={'/operators'} component={Operators} key={'operators'} />,
        <Route exact path={'/operator/new'} component={NewOperator} key={'operator-new'} />,
        <Route exact path={'/administrators'} component={Administrators} key={'administrators'} />,
        <Route exact path={'/administrator/new'} component={NewAdministrator} key={'administrator-new'} />,
        <Route exact path={'/configurations'} component={Configurations} key={'configurations'} />,
        <Route exact path={'/configuration/:id'} component={EditConfiguration} key={'configuration-edit'} />,
        <Route exact path={'/api-credentials'} component={ApiCredentialPage} key={'api-credentials'} />,
        <Route path={'*'} component={() => <Redirect to={'/private/operators'} />} key={'home-local-user'} />,
      ];
    }

    if (role === 'OPERATOR') {
      return [
        <Route exact path={'/mapper'} component={MapAttributes} key={'mapper'} />,
        <Route exact path={'/institution'} component={MyInstitution} key={'institution'} />,
        <Route exact path={'/my-account'} component={MyAccount} key={'my-account'} />,
        <Route exact path={'/user/activate/:code'} component={CreateSecurityCodeCallback}
               key={'active-user-callback'} />,
        <Route path={'*'} component={() => <Redirect to={'/private/mapper'} />} key={'home-federated-user'} />,
      ];
    }

    if (role === 'ADMIN') {
      return [
        <Route exact path={'/institution'} component={MyInstitution} key={'institution'} />,
        <Route exact path={'/my-account'} component={MyAccount} key={'my-account'} />,
        <Route exact path={'/operators'} component={Operators} key={'operators'} />,
        <Route exact path={'/operator/new'} component={NewOperator} key={'operator-new'} />,
        <Route exact path={'/administrators'} component={Administrators} key={'administrators'} />,
        <Route exact path={'/administrator/new'} component={NewAdministrator} key={'administrator-new'} />,
        <Route exact path={'/user/activate/:code'} component={CreateSecurityCodeCallback}
               key={'active-user-callback'} />,
        <Route path={'*'} component={() => <Redirect to={'/private/mapper'} />} key={'home-federated-user'} />,
      ];
    }

    return [];
  };

  return (
    <Switch>
      <Redirect from='/:url*(/+)' to={location?.pathname?.slice(0, -1)} />
      <Route exact path={'/'} component={Home} />
      <Route exact path={'/callback'} component={CallBackPage} />
      <Route exact path={'/login'} component={Login} />
      <Route exact path={'/user/activate/:code'} component={ActivateUser} />
      <PrivateRoute basePath={'/private'} authorized={isAuthenticated}>
        {renderRoutes()}
      </PrivateRoute>
    </Switch>
  );
};
export default Routes;