import styled from 'styled-components';

export const Container = styled('div')(({theme}) => ({
  width: '100%',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.bgColors.primaryDefault,
  color: 'white',
  fontSize: '1rem',
  boxShadow: '0px 3px 6px ' + theme.auxColors.boxShadow,
  'a': {
    color: 'inherit',
    transition: 'all 0.3s ease-in-out',
    padding: '0.5rem 0.75rem',
    borderBottom: '3px solid rgba(255,255,255,0)',
    ':hover': {
      color: theme.textColors.primaryDarken01,
      transform: 'scale(1.025)',
      borderBottom: '3px solid rgba(255,255,255,0.25)',
    },
  },
  'a.active': {
    borderBottom: '3px solid rgba(255,255,255,0.5)',
    ':hover': {
      borderBottom: '3px solid rgba(255,255,255,1)',
    }
  },
}));

export const Logo = styled('div')(({theme}) => ({
  height: '40px',
  padding: '0 1rem',
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'end',
  fontSize: '1.3725rem',
  fontWeight: '900',
  'a': {
    display: 'block',
    padding: '0',
    border: '0',
    color: 'white',
    borderBottom: '3px solid rgba(255,255,255,0) !important',
    ':hover': {
      border: '0'
    }
  },
  'img': {
    height: '40px',
    width: '100%',
    display: 'block',
    border: '0',
    ':hover': {
      border: '0'
    }
  },
  '> span': {
    color: '#4b80d5'
  }
}));

export const Actions = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
}));