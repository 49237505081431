import jwtDecode from 'jwt-decode';
import { UserType } from '../types/UserType';

export const AuthenticationHelper = {
  /**
   * Returns the type of the user based on the token stored in the session storage.
   * If the token is invalid or not available, it returns 'UNKNOWN'.
   * If the user is federated, it returns 'FEDERATED'.
   * If the user is local, it returns 'LOCAL'.
   *
   * @returns {UserType} The type of the user ('UNKNOWN', 'FEDERATED', or 'LOCAL').
   */
  getUserType: (): UserType => {
    const token = sessionStorage.getItem('token');

    if (token != null) {
      try {
        const user: any = jwtDecode(token);

        if ('idp_id' in user)
          return 'FEDERATED';
        else
          return 'LOCAL';
      } catch (error) {
        console.warn('Invalid token');
        return 'UNKNOWN';
      }
    }
    return 'UNKNOWN';
  }
};