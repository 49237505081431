import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';

interface RequestModalProps {
  open: boolean;
  content: ReactElement,
  hasError?: boolean;
  handleClose: () => void;
}

export const RequestModal = ({ open, content, hasError, handleClose }: RequestModalProps) => {
  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={handleClose}>
      <DialogTitle>Ativação de usuário</DialogTitle>
      <DialogContent style={{ textAlign: 'center', margin: '0.5rem auto' }}>
        {content}
      </DialogContent>
      <DialogActions>
        {hasError === true
          ? <Button onClick={handleClose} color='primary'>Voltar a tela inicial</Button>
          : <Button onClick={handleClose} color='default'>Voltar</Button>}
      </DialogActions>
    </Dialog>
  );
};
