import * as Yup from 'yup';

Yup.addMethod(
  Yup.string,
  'password',
  function validatePassword(message = 'Senha com formato inválido') {
    return this.test('password', message, function testPassword(password) {
      const { path, createError } = this;

      const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[#?!@$%^&*-])[A-Za-z\d@$!%*#?&]{0,}$/g;
      const str = password;
      let matches = regex.exec(str);
      while (matches !== null) {
        matches = regex.exec(str);
        if (matches.index === regex.lastIndex) {
          regex.lastIndex += 1;
        }
        return true;
      }
      return createError({ path, message });
    });
  }
);

Yup.addMethod(
  Yup.string,
  'passwordNotEqualEmailName',
  function validatePasswordNotEqualEmailName(
    message = 'Senha não pode conter Email ou seu Nome',
    val
  ) {
    return this.test(
      'passwordNotEqualEmailName',
      message,
      function testPasswordNotEqualEmailName(password) {
        const { path, createError } = this;

        if (
          password.includes(val.firstName) ||
          password.includes(val.lastName) ||
          password.includes(val.email)
        ) {
          return createError({ path, message });
        }
        return true;
      }
    );
  }
);

Yup.addMethod(
  Yup.string,
  'passwordNotEqual',
  function validatePasswordNotEqual(
    message = 'As senhas não conferem',
    objPassword
  ) {
    return this.test(
      'passwordNotEqual',
      message,
      function testPasswordNotEqual() {
        const { path, createError } = this;

        if (objPassword.password_repeat !== objPassword.password) {
          return createError({ path, message });
        }
        return true;
      }
    );
  }
);
