import styled from 'styled-components';

export const Container = styled('div')(({theme}) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.bgColors.primaryDefault,
  color: 'white',
  fontSize: '0.875rem',
  boxShadow: '0px 3px 6px ' + theme.auxColors.boxShadow,
}));