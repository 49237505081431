import { useEffect, useMemo, useState } from 'react';

interface SecurityCodeRequirementsProps {
  securityCode: string;
  setCorrectSecurityCode: (value: boolean) => void;
}

export const SecurityCodeRequirements = ({ securityCode, setCorrectSecurityCode }: SecurityCodeRequirementsProps) => {
  const [regexOneNumber] = useState<RegExp>(new RegExp('(?=.*[0-9])'));
  const [regexOneLetter] = useState<RegExp>(new RegExp('(?=.*[a-zA-Z])'));
  const [regexOneSpecialCharacter] = useState<RegExp>(new RegExp('(?=.*[!@#$%^&*])'));
  const [regexLength] = useState<RegExp>(new RegExp('(?=.{8,32})'));

  const verifyOneNumber = useMemo(() => regexOneNumber.test(securityCode), [regexOneNumber, securityCode]);
  const verifyOneLetter = useMemo(() => regexOneLetter.test(securityCode), [regexOneLetter, securityCode]);
  const verifyOneSpecialCharacter = useMemo(() => regexOneSpecialCharacter.test(securityCode), [regexOneSpecialCharacter, securityCode]);
  const verifyLength = useMemo(() => regexLength.test(securityCode), [regexLength, securityCode]);
  const isCorrectSecurityCode = verifyOneNumber && verifyOneLetter && verifyOneSpecialCharacter && verifyLength;

  useEffect(() => {
    setCorrectSecurityCode(isCorrectSecurityCode);
  }, [isCorrectSecurityCode, setCorrectSecurityCode]);

  const renderListItem = (index: number, text: string, isValid: boolean) => {
    const styleColor = isValid ? 'green' : '#aaa';
    return <li key={index} style={{ color: styleColor }}>{text}</li>;
  }

  const passwordRequirements = [
    { text: 'Mínimo de 8 caracteres', validator: verifyLength },
    { text: 'Pelo menos uma letra', validator: verifyOneLetter },
    { text: 'Pelo menos um número', validator: verifyOneNumber },
    { text: 'Pelo menos um caractere especial', validator: verifyOneSpecialCharacter },
  ];

  const getPasswordList = () => {
    const listStyle = { marginLeft: '1.4rem', fontSize: '1rem', listStyleType: 'disc' };

    return (
      <ul style={listStyle}>
        {passwordRequirements.map(((req, index) => renderListItem(index, req.text, securityCode !== '' ? req.validator : false)))}
      </ul>
    );
  }


  return (
    <div>
      <p style={{ margin: '0 0 0.4rem', maxWidth: '100%', fontSize: '1rem', fontWeight: 'bold' }}>O código de segurança
        deve conter:</p>
      {getPasswordList()}
    </div>
  );

};