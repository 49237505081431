import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  padding: '2rem 0.75rem',
  // boxShadow: 'inset 0 0 5px 1px rgba(255,105,255,0.5)',
  [theme.breakpoints.up('md')]: {
    padding: '2rem 0'
  }
}));

export const Wrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up('md')]: {
    maxWidth: 960
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1200
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1360
  },
  'p': {
    [theme.breakpoints.up('md')]: {
      // maxWidth: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%'
    },
    [theme.breakpoints.up('xl')]: {
      // maxWidth: '50%',
    }
  }

}));

export const Table = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  color: theme.palette.primary.main,
  thead: {
    backgroundColor: theme.bgColors.primaryPastel02,
    th: {
      padding: '0.5rem 0.75rem'
    }
  },
  tbody: {
    tr: {
      ':nth-child(even)': {
        backgroundColor: '#f3f3f3'
      },
      td: {
        padding: '0.875rem 0.75rem',
        fontSize: '0.875rem'
      },
      'td.flex': {
        display: 'flex',
        justifyContent: 'center',
        gap: '0.75rem',
        padding: '0.875rem 0.75rem'
      }
    }
  },
  overflowY: 'auto',

  '::-webkit-scrollbar': {
    width: '6px'
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '3px'
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.25)',
    borderRadius: '3px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0,0,0,0.5)'
  }

}));

export const Box = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 0,
  width: '100%',
  display: 'block',
  margin: '1rem auto',
  [theme.breakpoints.up('md')]: {
    border: '1px solid #0C326F',
    borderRadius: '1rem',
    padding: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '1.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '2rem'
  },
  label: {
    display: 'block',
    fontWeight: '700'
  },
  '.MuiFormControl-root': {
    margin: '1rem 0'
  },
  table: {
    ...Table
  }
}));

export const Atributo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '100%',
  '.MuiFormControl-root': {
    flexGrow: 1,
    margin: 0
  },
}));

export const Atributos = styled('div')(({ theme }) => ({
  maxHeight: '80vh',
  overflowY: 'auto',
  // padding: '0',
  border: '1px solid #d0d0d0',
  borderRadius: '0.75rem',
  margin: '1rem 0',
  [theme.breakpoints.up('md')]: {
    maxHeight: '360px'
  },
  ' > div': {
    padding: '1rem'
  },
  ' > div:not(:last-child)': {
    // marginBottom: '1.5rem',
    borderBottom: '1px solid #d0d0d0'
  },
  h4: {
    margin: '0 0 0.25rem 0',
    color: theme.bgColors.primaryLighten02
  },
  p: {
    fontSize: '0.875rem',
    margin: '0 0 0 18px',
    color: theme.bgColors.colorSecondary05
  },
  '.active > h4': {
    color: theme.bgColors.primaryDarken01
  },
  '.active > p': {
    color: theme.bgColors.primaryDefault
  },
  '.active': {
    backgroundColor: '#f6f6f6'
  },

  '::-webkit-scrollbar': {
    width: '6px'
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '3px'
  },
  '::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,0.25)',
    borderRadius: '3px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0,0,0,0.5)'
  }

}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontSize: '1rem',
  fontWeight: 'bold'
}));