import { NavLink, useHistory } from 'react-router-dom';


import { Actions, Container, Logo } from './styles';
import { UserMenu } from './UserMenu';
import { useLogoutUserMutation } from '../../config/api/authenticationEndpoints';
import { useRole } from '../../hooks';
import { useLogoutMutation } from '../../config/api/idpUserEndpoints';
import MainLogo from '../../assets/img/icpedu-logo-white.svg';


const Navbar = () => {
  const [logoutUser] = useLogoutUserMutation();
  const [logoutFederalUser] = useLogoutMutation();
  const history = useHistory();
  
  const role = useRole();

  const handleLogout = () => {
    if (role === 'MASTER')
      logoutUser().unwrap().finally(cleanStorageAndRedirect);
    else
      logoutFederalUser().unwrap().finally(cleanStorageAndRedirect);
  };

  const cleanStorageAndRedirect = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };
  
  const handleMyAccount = () => {
    history.push('/private/my-account');
  };

  const getDefaultPath = () => {
    if (role === 'MASTER')
      return '/private/operators';
    if (role === 'OPERATOR')
      return '/private/mapper';
    if (role === 'ADMIN')
      return '/private/operators';
    else
      return '/';
  };
  
  return (
    <Container>
      <Logo>
        <NavLink to={getDefaultPath()} exact={true}><img src={MainLogo} alt='ICPEdu' /></NavLink>
        <span>|</span>
        <NavLink to={getDefaultPath()} exact={true}>Mapeador de atributos</NavLink>
      </Logo>
      <Actions>
        {role === 'MASTER' &&
          <>
            <NavLink to='/private/operators'>Operadores</NavLink>
            <NavLink to='/private/administrators'>Administradores</NavLink>
            <NavLink to='/private/configurations'>Configurações</NavLink>
            <NavLink to='/private/api-credentials'>Chaves de API</NavLink>
          </>}
        {role === 'OPERATOR' &&
          <>
            <NavLink to='/private/mapper'>Mapear atributos</NavLink>
            <NavLink to='/private/institution'>Minha instituição</NavLink>
          </>}
        {role === 'ADMIN' &&
          <>
            <NavLink to='/private/operators'>Operadores</NavLink>
            <NavLink to='/private/administrators'>Administradores</NavLink>
            <NavLink to='/private/institution'>Minha instituição</NavLink>
          </>}
        <UserMenu
          handleLogout={handleLogout}
          handleMyAccount={handleMyAccount}
        />
      </Actions>
    </Container>
  );
};

export default Navbar;