import { useHistory, useParams } from 'react-router-dom';
import { Container, Header, Wrapper } from '../Home/styles';
import { Button, CircularProgress, Grid, IconButton, Input } from '@material-ui/core';
import Footer from '../../components/Footer/Index';
import { Box } from '../../assets/styles/defaultStyles';
import { Actions } from './styles';
import { ReactElement, useState } from 'react';
import { useCreateValidationCodeMutation, useGetMeQuery } from '../../config/api/idpUserEndpoints';
import { useAppSelector } from '../../config/store/hooks';
import ModalFeedbackUserVerification from './components/ModalFeedbackUserVerification';
import { RequestModal } from './components/RequestModal';
import { useModalVisibility, useRole } from '../../hooks';
import { SecurityCodeRequirements } from './components/SecurityCodeRequirements';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { UserClaims } from '../../types/UserClaims';
import { ExternalURLsHelper } from '../../helpers/ExternalURLsHelper';


const CreateSecurityCodeCallback = () => {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const { data, isSuccess, isError, isFetching } = useGetMeQuery();
  const [createValidationCode] = useCreateValidationCodeMutation();
  const requestModalState = useModalVisibility();
  const user = useAppSelector(state => state.user.claims);
  const role = useRole();

  const [securityCode, setSecurityCode] = useState('');
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [confirmSecurityCode, setConfirmSecurityCode] = useState('');
  const [showConfirmSecurityCode, setShowConfirmSecurityCode] = useState(false);
  const [requirementsSecurityCode, setRequirementsSecurityCode] = useState(false);
  const [requestModalContent, setRequestModalContent] = useState<ReactElement>(<></>);

  const fetchIcon = <CircularProgress size={50} style={{ margin: 25 }} />;
  const errorIcon = <FaExclamationCircle color='#E60000' size={100} />;
  const successIcon = <FaCheckCircle color='#268744' size={100} />;

  const canSubmit = () => (securityCode === confirmSecurityCode && securityCode !== '' && confirmSecurityCode !== '' && requirementsSecurityCode);

  const getAttributeValueAsString = (attribute: keyof UserClaims): string | null => {
    if (user && user[attribute]) {
      return typeof user[attribute] === 'string' ? user[attribute] as string : (user[attribute] as string[])[0];
    }

    return null;
  };

  const verifyUser = () => {
    const userDateReady = isSuccess && data && user;

    if (userDateReady) {
      return user.fixedEmail[0] === data.email && user.idp_id === data.identityProvider.idpId;
    }

    return false;
  };

  const buildModalContent = (icon: ReactElement, text: string) => {
    return (
      <>
        {icon}
        <h2>{text}</h2>
      </>
    );
  };

  const openFeedbackModal = (icon: ReactElement, text: string, hasError?: boolean) => {
    const modalContent = buildModalContent(icon, text);

    return <ModalFeedbackUserVerification open={true} content={modalContent} hasError={hasError} />;
  };

  if (isFetching) {
    openFeedbackModal(fetchIcon, 'Carregando informações do usuário, aguarde...');
  }

  if (isError) {
    openFeedbackModal(errorIcon, 'Ocorreu um erro de comunicação com o servidor, tente novamente mais tarde.', true);
  }

  if (!verifyUser()) {
    openFeedbackModal(errorIcon, 'Erro de validação de usuário. Verifique com o administrador de sua instituição e tenta novamente.', true);
  }

  const handleSubmit = () => {
    user && createValidationCode({
      email: user.fixedEmail[0],
      securityCode: securityCode,
      name: getAttributeValueAsString('displayName') ?? '',
      username: getAttributeValueAsString('eduPersonPrincipalName') ?? '',
      identityProviderId: user?.idp_id,
      accessCode: code ?? '',
    }).unwrap().then(() => {
      setRequestModalContent(buildModalContent(successIcon, 'Código de segurança salvo com sucesso! Redirecionando para o Mapeador de Atributos...'));
      setTimeout(() => {
        if (role === 'OPERATOR') history.push('/private/mapper');
        else history.push('/private/operators');
      }, 3000);
    }).catch(() => {
      setRequestModalContent(buildModalContent(errorIcon, 'Ocorreu um erro ao salvar o código de segurança, tente novamente mais tarde.'));
      setTimeout(() => {
        requestModalState.closeModal();
        window.location.href = '/';
      }, 3000);
    }).finally(() => {
      sessionStorage.removeItem('state');
    });

    setRequestModalContent(buildModalContent(fetchIcon, 'Salvando código de segurança, aguarde...'));
    requestModalState.openModal();
  };

  const handleClose = () => {
    requestModalState.closeModal();
  };

  return (
    <>
      <Header>
        <Button variant='outlined' href={ExternalURLsHelper.getAjudaRNPURL()} target='_blank' rel="noreferrer">Ajuda RNP</Button>
      </Header>

      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>Defina seu código de segurança</h1>
              <p style={{ fontSize: '1.25rem' }}>Uma autenticação a ser utilizada internamente ao serviço
                do Mapeador de Atributos na confirmação de ações protegidas</p>
            </div>


            <Box style={{ width: '30%' }}>
              <Grid container={true} spacing={3}>
                <Grid item xs={12}>
                  <SecurityCodeRequirements securityCode={securityCode}
                                            setCorrectSecurityCode={setRequirementsSecurityCode} />
                </Grid>


                <Grid item xs={12}>
                  <label>Código de segurança</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.9rem' }}>
                    <Input
                      style={{ width: '100%' }}
                      type={showSecurityCode ? 'text' : 'password'}
                      placeholder='Código de segurança'
                      onChange={e => setSecurityCode(e.target.value)} />
                    <IconButton onClick={() => setShowSecurityCode(!showSecurityCode)}>
                      {showSecurityCode ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <label>Confirme o código de segurança</label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', height: '1.9rem', gap: '2' }}>
                    <Input
                      style={{ width: '100%' }}
                      type={showConfirmSecurityCode ? 'text' : 'password'}
                      placeholder='Repita o código de segurança'
                      onChange={e => setConfirmSecurityCode(e.target.value)} />
                    <IconButton onClick={() => setShowConfirmSecurityCode(!showConfirmSecurityCode)}>
                      {showConfirmSecurityCode ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Actions>
              <div style={{ textAlign: 'center' }}><Button type='submit' size='large'
                                                           style={{ padding: '0.60rem 1rem', fontSize: '1rem' }}
                                                           disabled={!canSubmit()}
                                                           onClick={handleSubmit}
              >Salvar</Button>
              </div>
              {securityCode !== confirmSecurityCode &&
                <p style={{ color: 'red' }}>Os códigos de segurança não coincidem</p>}
            </Actions>
          </div>
          <RequestModal
            open={requestModalState.isVisible}
            content={requestModalContent}
            handleClose={handleClose}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default CreateSecurityCodeCallback;
