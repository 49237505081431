import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { FaSignOutAlt, FaUser, FaUserCircle } from 'react-icons/fa';
import React, { useEffect, useRef, useState } from 'react';
import { useRole } from '../../hooks';

interface UserMenuProps {
  handleLogout: () => void;
  handleMyAccount: () => void;
}

export const UserMenu = ({ handleLogout, handleMyAccount }: UserMenuProps) => {
  const role = useRole();
  
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (!open && prevOpen.current)
      anchorRef.current!.focus();

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button ref={anchorRef} onClick={handleToggle} style={{ border: 0, boxShadow: 'none' }}>
        <FaUserCircle size={30} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
                  {role !== 'MASTER' && (
                    <MenuItem onClick={handleMyAccount}>
                      Minha conta
                      <FaUser size={20} style={{ marginLeft: 5 }} />
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleLogout}>
                    Sair
                    <FaSignOutAlt size={20} style={{ marginLeft: 5 }} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};