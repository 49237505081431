import { Pagination, Skeleton } from '@material-ui/lab';
import {
  useDeleteApiCredentialMutation,
  useGetAllApiCredentialsQuery,
} from '../../../config/api/apiCredentialEndpoints';
import { useAppTranslation, useModalVisibility, usePagination } from '../../../hooks';
import { Box } from '../styles';
import { FaCopy, FaTrash } from 'react-icons/fa';
import { ConfirmationModal } from '../../../components/ConfirmationModal/Index';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { SecurityCodeModal } from '../../../components/SecurityCodeModal/SecurityCodeModal';

export const Table = () => {
  const [t] = useAppTranslation('API_KEY_LIST');
  const [pageable, onPageChange] = usePagination({});
  const { data, isFetching, isError, isSuccess } = useGetAllApiCredentialsQuery(pageable);
  const warningModal = useModalVisibility();
  const [selectedApiKey, setSelectedApiKey] = useState<string>('');
  const [deleteApiCredential] = useDeleteApiCredentialMutation();
  const { enqueueSnackbar } = useSnackbar();

  /* Security Code modal */
  const securityCodeModalState = useModalVisibility();
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);
  
  const handleDeleteClick = (apiKeyToRemove: string) => {
    setSelectedApiKey(apiKeyToRemove);
    warningModal.openModal();
  };

  const handleConfirmSecurityCode = (securityCode: string) => {
    deleteApiCredential([selectedApiKey, securityCode])
      .unwrap()
      .then(() => {
        warningModal.closeModal();
        securityCodeModalState.closeModal();
        enqueueSnackbar(t('REMOVE_SUCCESS_MESSAGE'), { variant: 'success' });
      })
      .catch((error) => {
        if (error.status === 403)
          setIsWrongSecurityCode(true);
        else
          enqueueSnackbar(t('REMOVE_ERROR_MESSAGE'), { variant: 'error' });
      });
  };

  const handleCopyClick = (apiKey: string) => {
    navigator.clipboard.writeText(apiKey).then(() => {
      enqueueSnackbar(t('COPY_SUCCESS_MESSAGE'), { variant: 'success' });
    }).catch((error) => {
      enqueueSnackbar(t('COPY_ERROR_MESSAGE'), { variant: 'error' });
      console.error(error);
    });
  };

  return (
    <>
      <Box>

        {!isFetching
          ?
          <>
            <table>
              <thead>
              <tr>
                <th>{t('TABLE_HEADER_APPLICATION_URL')}</th>
                <th>{t('TABLE_HEADER_DESCRIPTION')}</th>
                <th>{t('TABLE_HEADER_ACTIONS')}</th>
              </tr>
              </thead>
              {isError && <tbody>
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>{t('TABLE:ERROR')}</td>
              </tr>
              </tbody>}
              {(!isFetching && isSuccess && data.content.length > 0 && <tbody>
              {data.content.map((apiCredential) => {
                return (
                  <tr key={apiCredential.applicationUrl}>
                    <td>{apiCredential.applicationUrl}</td>
                    <td>{apiCredential.description ?? '-'}</td>
                    <td className='flex'>
                   <span title={t('COPY_API_KEY_TITLE')}
                         onClick={() => handleCopyClick(apiCredential.apiKey)}><FaCopy cursor='pointer' /> </span>
                      <span title={t('REMOVE_API_KEY_TITLE')}
                            onClick={() => handleDeleteClick(apiCredential.apiKey)}><FaTrash cursor='pointer' /> </span>
                    </td>
                  </tr>
                );
              })}
              </tbody>)}
            </table>
            <Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
                        count={data ? data.totalPages : 1}
                        page={pageable.pageNumber + 1} onChange={(_, page) => onPageChange(page - 1)} />
          </>
          :
          <>
            <Skeleton variant='rect' height={200} />
          </>
        }



      </Box>
      <ConfirmationModal open={warningModal.isVisible} title={t('REMOVE_MODAL_TITLE')} body={t('REMOVE_MODAL_BODY')}
                         onClose={warningModal.closeModal} onConfirm={() => {
        securityCodeModalState.openModal();
      }} />
      <SecurityCodeModal
        open={securityCodeModalState.isVisible}
        handleConfirm={handleConfirmSecurityCode}
        isWrongSecurityCode={isWrongSecurityCode}
        handleClose={() => {
          securityCodeModalState.closeModal();
          setIsWrongSecurityCode(false);
        }}
      />
    </>
  );
};