export const ExternalURLsHelper = {
  getAjudaRNPURL: () => {
    return process.env.REACT_APP_AJUDA_RNP_URL || '#';
  },

  getAssinadorURL: () => {
    return process.env.REACT_APP_ASSINADOR_URL || '#';
  },

  getSaecURL: () => {
    return process.env.REACT_APP_SAEC_URL || '#';
  },

  getRepositorioURL: () => {
    return process.env.REACT_APP_REPOSITORIO_URL || '#';
  }
};