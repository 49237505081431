import { baseApi } from './baseApi';
import { Pageable } from '../../types/Pageable';
import { IdentityProvider } from '../../types/IdentityProvider';
import { PageableHelper } from '../../helpers/PageableHelper';
import { Page } from '../../types/Page';
import { IdpUser } from '../../types/IdpUser';
import { defaultLargeCacheTime } from '../../helpers/environment';

export const identityProviderEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllIdentityProviders: builder.query<Page<IdentityProvider>, Pageable>({
      query: (pageable) => `/identity-providers?${PageableHelper.queryParamsFromPageable(pageable, false)}`,
      providesTags: ['IdentityProvider'],
      keepUnusedDataFor: defaultLargeCacheTime,
    }),
    getAllOperators: builder.query<IdpUser[], void>({
      query: () => `/identity-providers/operators`,
      providesTags: ['User']
    }),
    getMyIdentityProvider: builder.query<IdentityProvider, void>({
      query: () => `/identity-providers/my`,
      providesTags: ['User']
    }),
    createIdentityProviderContactInfo: builder.mutation<void, [IdentityProvider, string]>({
      query: ([identityProvider, securityCode]) => ({
        url: `/identity-providers/my`,
        method: 'PATCH',
        body: {
          ...identityProvider,
          securityCode: securityCode
        }
      }),
      invalidatesTags: ['User', 'IdentityProvider'],
    })
  }),
  overrideExisting: false,
});

export const {
  useGetAllIdentityProvidersQuery,
  useGetAllOperatorsQuery,
  useGetMyIdentityProviderQuery,
  useCreateIdentityProviderContactInfoMutation
} = identityProviderEndpoints;