import Navbar from '../../components/Navbar/Index';
import { Container, Wrapper } from '../Login/components/Configurations/List/styles';
import { Grid } from '@material-ui/core';
import Footer from '../../components/Footer/Index';
import { Table } from './components/Table';
import { useAppTranslation } from '../../hooks';
import { Form } from './components/Form';

export const ApiCredentialPage = () => {
  const [t] = useAppTranslation('API_KEY_LIST');
  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>{t('TITLE')}</h1>

            </div>

            <Grid container justifyContent='center'>
              <Grid item xs={12} md={8}>
                <Form />
              </Grid>

              <Grid item xs={12} md={8}>
                <h2 style={{ textAlign: 'center' }}>Listagem de chaves de api</h2>
                <Table />
              </Grid>

            </Grid>
          </div>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};