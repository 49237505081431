import Navbar from 'components/Navbar/Index';
import Footer from 'components/Footer/Index';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';

import { Box, Container, Wrapper } from './styles';
import { FaPen, FaRegQuestionCircle } from 'react-icons/fa';
import './styles.css';
import { useState } from 'react';
import { useGetConfigurationsQuery } from '../../../../../config/api/configurationEndpoints';
import { Link } from 'react-router-dom';
import { Pagination, Skeleton } from '@material-ui/lab';
import { useAppTranslation, usePagination } from '../../../../../hooks';
import { Sort } from '../../../../../types/Sort';


const Configurations = () => {
  const defaultSort: Sort[] = [{
    field: 'name',
    order: 'asc'
  }];
  const [t] = useAppTranslation('CONFIGURATIONS_LIST');
  const [term, setTerm] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageable, onPageChange] = usePagination({ sort: defaultSort });
  const { data, isSuccess, isFetching, isError } = useGetConfigurationsQuery({ term: searchTerm, pageable });

  const handleRemoveFilters = () => {
    setTerm('');
    setSearchTerm('');
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>{t('TITLE')}</h1>
              <p style={{
                fontSize: '1.25rem',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '0.25rem'
              }}>Consulte e altere alguns
                parâmetros internos do serviço.</p>
              <p style={{ fontSize: '1.25rem', margin: '0 auto 0.25rem auto' }}>Algumas alterações podem só ter efeitos
                após reinicialização da aplicação.</p>
              <p style={{ fontSize: '1.25rem', margin: '0.4rem auto 2rem' }}><b>Cuidado:</b> alterações indevidas e/ou
                incorretas podem levar ao mau funcionamento ou <b>interrupção do serviço</b> do Mapeador.</p>
            </div>

            <Grid container spacing={3}>

              <Grid item xs={12} md={4}>
                <h2 style={{ textAlign: 'center' }}>{t('FILTER_TITLE')}</h2>

                <Box>
                  {!isFetching ?
                    <>
                      <FormControl fullWidth>
                        <TextField label={t('FILTER_BY_NAME_PLACEHOLDER')} value={term}
                                   onChange={e => setTerm(e.target.value)} />
                      </FormControl>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}><Button variant='outlined'
                                                          onClick={handleRemoveFilters}>{t('FILTER:CLEAN_FILTERS')}</Button></Grid>
                        <Grid item xs={12} md={6}><Button
                          onClick={() => setSearchTerm(term)}>{t('FILTER:DO_FILTERS')}</Button></Grid>
                      </Grid>
                    </>
                    :
                    <Skeleton variant='rect' height={150} />
                  }
                </Box>

              </Grid>

              <Grid item xs={12} md={8}>
                <h2 style={{ textAlign: 'center' }}>{t('SUB_TITLE')}</h2>

                <Box>
                  {!isFetching ?
                    <>
                      <table>
                        <thead>
                        <tr>
                          <th>{t('TABLE_HEADER_NAME')}</th>
                          <th>{t('TABLE_HEADER_VALUE')}</th>
                          <th>{t('TABLE_HEADER_ACTIONS')}</th>
                        </tr>
                        </thead>
                        {isError && <tbody>
                        <tr>
                          <td colSpan={3} style={{ textAlign: 'center' }}>{t('TABLE:ERROR')}</td>
                        </tr>
                        </tbody>}
                        {(!isFetching && isSuccess && data.content.length > 0 && <tbody>
                        {data.content.map((configuration) => {
                          return (
                            <tr key={configuration.id}>
                              <td style={{ display: 'flex', justifyContent: 'space-between', gap: '0.4rem' }}>
                                <span>{configuration.name}</span>
                                <span><FaRegQuestionCircle title={t(`${configuration.name}`)} /></span>
                              </td>
                              <td>{configuration.value}</td>
                              <td className='flex'><Link to={`/private/configuration/${configuration.id}`}><FaPen
                                cursor='pointer' /> </Link>
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>)}
                      </table>

                      <Pagination style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
                                  count={data ? data.totalPages : 1}
                                  page={pageable.pageNumber + 1} onChange={(_, page) => onPageChange(page - 1)} />
                    </>
                    :
                    <>
                      <Skeleton variant='rect' height={300} />
                    </>
                  }
                </Box> 
              </Grid>

            </Grid>


          </div>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Configurations;