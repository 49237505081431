import styled from 'styled-components';

export const Container = styled('div')(({theme}) => ({
  padding: '2rem 0.75rem',
  // boxShadow: 'inset 0 0 5px 1px rgba(255,105,255,0.5)',
  [theme.breakpoints.up("md")]: {
    padding: '2rem 0',
  },
}));

export const Wrapper = styled('div')(({theme}) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up("md")]: {
    maxWidth: 960,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1200,
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 1360,
  },
}));

export const Field = styled('div')(({theme}) => ({
  display: 'flex',
  gap: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '100%',
  margin: '1rem auto',
  [theme.breakpoints.up("md")]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: '400px',
  },
  '.MuiInputBase-root': {
    flexGrow: 1,
  },
}));
