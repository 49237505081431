import { FaClipboard } from 'react-icons/fa';
import React from 'react';
import { ClipboardHelper } from '../../../helpers/ClipboardHelper';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Atributo } from '../styles';
import { useAppTranslation } from '../../../hooks';

interface StaticAttributeProps {
  id: string;
  name: string;
  usedValues: string[];
  allValues: string[];
  hasError: boolean;
  errorMessage: string;
  isMultiValued: boolean;
  required?: boolean;
  initialValue?: string[];
  onChange?: (value: string[] | string, oldValue: string[] | string, id: string) => boolean;
}

export const StaticAttribute = ({
                                  id,
                                  usedValues,
                                  allValues,
                                  name,
                                  hasError,
                                  errorMessage,
                                  isMultiValued,
                                  initialValue,
                                  onChange,
                                }: StaticAttributeProps) => {
  const [t] = useAppTranslation('STATIC_ATTRIBUTE');
  const [value, setValue] = React.useState<string[]>(initialValue ?? []);
  
  
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };
  
  /**
   * If the component is multivalued, newValue is appended to the current value.
   * Otherwise, the current value is replaced by newValue.
   *
   * The change is only applied if onChange returns true, indicating that the change is valid.
   * 
   * @param newValue - new value to be set
   *          - if component is multivalued, newValue should be a string array
   *          - otherwise, newValue should be a string
   * */
  const updateValue = (newValue: string[] | string) => {
    if (onChange && onChange(newValue, value, id))
      setValue(newValue as string[]);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const key = e.dataTransfer.getData('text/plain');

    updateValue(isMultiValued ? [...value, key] : key);
  };

  const handleClick = async () => {
    const newValue = await ClipboardHelper.getFromClipboard() ?? '';

    updateValue(isMultiValued ? [...value, newValue] : newValue);
  };
  
  return (
    <>
      <Grid item xs={12} md={6} onDragOver={handleDragOver} onDrop={handleDrop}>
        <Atributo>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', width: '100%' }}>
            <Autocomplete
              value={value} //TODO: Warning: quando monovalorado, é esperado uma string e não um array
              autoComplete={false}
              
              options={allValues.sort((a, _) => usedValues.includes(a) ? 1 : -1)} // not used values first
              getOptionLabel={(option) => option.toString()}
              groupBy={(option) => usedValues.includes(option) ? 'Mapeados' : 'Não mapeados'}

              noOptionsText={t('COMMONS:NO_OPTIONS')}
              
              fullWidth={true}
              renderInput={(params) => {
                return <TextField
                  {...params}
                  variant="standard"
                  label={t(name)}
                />
              }}
              
              multiple={isMultiValued}
              size={isMultiValued ? 'small' : undefined}
              
              getOptionDisabled={(option) => isMultiValued ? false :  usedValues.includes(option)} // disable used values if not multi-valued
              onChange={(_, newValue) => {
                updateValue(newValue == null ? [] : newValue);
              }}
            />
            <FaClipboard style={{ cursor: 'pointer' }} onClick={handleClick} />
          </div>
          <div>
            {hasError && <span style={{ color: 'red', marginTop: '5px'}}>{errorMessage}</span>}
          </div>
        </Atributo>
      </Grid>
    </>
  );
};