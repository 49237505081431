import styled from 'styled-components';

export const Container = styled('div')(({theme}) => ({
  padding: '2rem 0.75rem',
  [theme.breakpoints.up("md")]: {
    padding: '2rem 0',
  },
}));

export const Wrapper = styled('div')(({theme}) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up("md")]: {
    maxWidth: 960,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1200,
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 1360,
  },
  'p': {
    [theme.breakpoints.up("md")]: {
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up("xl")]: {
    },
  },

}));

export const Box = styled('div')(({theme}) => ({
  boxSizing: 'border-box',
  padding: 0,
  width: '100%',
  display: 'block',
  margin: '1rem auto',
  [theme.breakpoints.up("md")]: {
    border: '1px solid #0C326F',
    borderRadius: '1rem',
    padding: '1rem',
  },
  [theme.breakpoints.up("lg")]: {
    padding: '1.5rem',
  },
  [theme.breakpoints.up("xl")]: {
    padding: '2rem',
  },
  label: {
    display: 'block',
    fontWeight: '700',
  },
  '.MuiInputBase-root': {
    display: 'block',
  }

}));

export const Operador = styled('div')(({theme}) => ({
  'h3': {
    margin: 0,
  },
  'p': {
    margin: '0.375rem 0 0 20px',
  },
  ':not(:last-child)': {
    marginBottom: '2rem',
  }
}));
