import { Pageable } from '../types/Pageable';
import { SortOrder } from '../types/Sort';

export const PageableHelper = {
  createPageable: (page: number, size: number, sortField: string, order: SortOrder): Pageable => ({
    pageNumber: page,
    pageSize: size,
    sort: [{
      field: sortField,
      order: order
    }]
  }),
  /**
   * Generates query parameters from a Pageable object.
   *
   * @param {Pageable} pageable - The Pageable object containing information about page number, page size, and sort.
   * @param {boolean} [includeAmpersand=true] - Whether to include an ampersand before the query parameters.
   * @returns {string} The generated query parameters string.
   */
  queryParamsFromPageable: (pageable: Pageable, includeAmpersand: boolean = true): string => {
    const { pageNumber, pageSize, sort } = pageable;
    const sortQuery = sort ? sort.map(s => `&sort=${s.field},${s.order}`).join('') : '';

    return `${includeAmpersand ? '&' : ''}page=${pageNumber}&size=${pageSize}${sortQuery}`;
  }
};
