import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../helpers/environment';
import { AuthenticationHelper } from '../../helpers/AuthenticationHelper';

const baseQuery = fetchBaseQuery({
  baseUrl: `${apiUrl}api/v1`,
  prepareHeaders: (headers, _) => {
    const token = sessionStorage.getItem('token');

    if (token) headers.set('Authorization', `Bearer ${token}`);

    return headers;
  }
});

const baseQueryWithTokenTimeout: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  if (AuthenticationHelper.getUserType() === 'LOCAL') {
    if (typeof args === 'string' && !args.includes('/local'))
      args = `/local${args.startsWith('/') ? '' : '/'}${args}`;
    else if (typeof args === 'object' && !args.url.includes('/local'))
      args.url = `/local${args.url.startsWith('/') ? '' : '/'}${args.url}`;
  }
  
  let result = await baseQuery(args, api, extraOptions);
  const token = sessionStorage.getItem('token');

  if (token && result.error && result.error.status === 401) {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  }

  return result;
};
export const baseApi = createApi({
  baseQuery: baseQueryWithTokenTimeout,
  tagTypes: ['Configuration', 'LocalUser', 'IdentityProvider', 'User', 'ApiCredential', 'StaticAttribute', 'Mapper'],
  endpoints: () => ({})
});

