import { useQuery } from '../../hooks';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { UserClaims } from '../../types/UserClaims';
import { setUser } from '../../config/store/slices/user.slice';
import { useSnackbar } from 'notistack';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { useGetMeQuery } from '../../config/api/idpUserEndpoints';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const CallBackPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { hash } = useLocation();
  const classes = useStyles();
  const query = useQuery(hash.replace('#', '?'));
  const token = query.get('id_token');
  let userClaims: UserClaims;
  const { data, error } = useGetMeQuery(token ?? '');

  if (data === undefined && error === undefined) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color='inherit' />
      </Backdrop>);
  }

  const cleanStatesAndRefreshPage = () => {
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };
  
  if (error) {
    if (query.get('state') == null)
      enqueueSnackbar('Ocorreu um erro na autenticação, tente novamente.', { variant: 'warning' });
    else {
      enqueueSnackbar('Dados inválidos, verifique com o administrador.', { variant: 'error' });
    }
    cleanStatesAndRefreshPage();
    return <Redirect push to={'/'} />;
  }

  /* State stores the identifier code for new Operators */
  const state = query.get('state');
  if ((state != null && state !== '' && state !== sessionStorage.getItem('state')) || token === null || token === '') {
    enqueueSnackbar('Ocorreu um erro, tente novamente.', { variant: 'error' });
    cleanStatesAndRefreshPage();
    return <Redirect push to={'/'} />;
  }

  try {
    userClaims = jwt_decode<UserClaims>(token);
  } catch (error) {
    console.error(error);
    enqueueSnackbar('Erro ao decodificar o token!', { variant: 'error' });
    cleanStatesAndRefreshPage();
    return <Redirect push to={'/'} />;
  }


  sessionStorage.setItem('token', token);

  if (userClaims.nonce !== sessionStorage.getItem('nonce')) {
    enqueueSnackbar('Ocorreu um erro, tente novamente.', { variant: 'error' });
    cleanStatesAndRefreshPage();
    return <Redirect push to={'/'} />;
  }

  const userDetails = data ?? null;

  dispatch(setUser({ claims: userClaims, details: userDetails }));

  if (userDetails && state && (userDetails.status === 'PENDING_VALIDATION' || userDetails.status === 'PENDING_ACTIVATION' || userDetails.status === 'ACTIVE')) {
    return <Redirect to={`/private/user/activate/${state}`} />;
  }

  if (userDetails && userDetails.status !== 'ACTIVE') {
    enqueueSnackbar('Usuário não ativado!', { variant: 'warning' });
    cleanStatesAndRefreshPage();
    return <Redirect push to={'/'} />;
  }

  const pathname = sessionStorage.getItem('pathname');

  if (pathname) {
    return <Redirect push to={pathname} />;
  }

  if (userDetails && userDetails.role === 'ADMIN')
    return <Redirect push to={'/private/operators'} />;
  
  return <Redirect push to={'/private/mapper'} />;
};