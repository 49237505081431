import {createGlobalStyle} from 'styled-components';

import {FONT_FAMILY} from 'constants/fonts';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    font-family: ${FONT_FAMILY};
    font-weight: 500;
    font-feature-settings: 'lnum';
    -webkit-font-smoothing: antialiased;
    color: #5d5d5d;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #0C326F;
    font-weight: 800;
    margin: 0.67rem 0;
  }

  p {
    margin: 1rem 0;
    color: #0C326F;
  }

  a {
    text-decoration: none;
    color: #0C326F;
    font-weight: 600;
  }
  
  ul {
    list-style: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }


.react-datepicker {
  font-family: ${FONT_FAMILY};
}

.react-datepicker__header {
  background-color: #f0f0f0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__current-month {
  text-transform: capitalize;
  color: ${props => props.theme.palette.primary.main};
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__navigation {
  color: ${props => props.theme.palette.primary.main};
}

.react-datepicker__day-names {
  text-transform: capitalize;
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: ${props => props.theme.palette.primary.main};
  color: #fff !important;
}

.react-datepicker__day + :hover {
  border-radius: 50%;
}

.react-datepicker__day {
  color: ${props => props.theme.palette.primary.main};
}


.max-w-10 { max-width: 10%; margin-left: auto; margin-right: auto; }
.max-w-20 { max-width: 20%; margin-left: auto; margin-right: auto; }
.max-w-25 { max-width: 25%; margin-left: auto; margin-right: auto; }
.max-w-30 { max-width: 30%; margin-left: auto; margin-right: auto; }
.max-w-40 { max-width: 40%; margin-left: auto; margin-right: auto; }
.max-w-50 { max-width: 50%; margin-left: auto; margin-right: auto; }
.max-w-60 { max-width: 60%; margin-left: auto; margin-right: auto; }
.max-w-70 { max-width: 70%; margin-left: auto; margin-right: auto; }
.max-w-75 { max-width: 75%; margin-left: auto; margin-right: auto; }
.max-w-80 { max-width: 80%; margin-left: auto; margin-right: auto; }
.max-w-90 { max-width: 90%; margin-left: auto; margin-right: auto; }
@media (min-width: 900px) {
  .max-w-md-10 { max-width: 10%; margin-left: auto; margin-right: auto; }
  .max-w-md-20 { max-width: 20%; margin-left: auto; margin-right: auto; }
  .max-w-md-25 { max-width: 25%; margin-left: auto; margin-right: auto; }
  .max-w-md-30 { max-width: 30%; margin-left: auto; margin-right: auto; }
  .max-w-md-40 { max-width: 40%; margin-left: auto; margin-right: auto; }
  .max-w-md-50 { max-width: 50%; margin-left: auto; margin-right: auto; }
  .max-w-md-60 { max-width: 60%; margin-left: auto; margin-right: auto; }
  .max-w-md-70 { max-width: 70%; margin-left: auto; margin-right: auto; }
  .max-w-md-75 { max-width: 75%; margin-left: auto; margin-right: auto; }
  .max-w-md-80 { max-width: 80%; margin-left: auto; margin-right: auto; }
  .max-w-md-90 { max-width: 90%; margin-left: auto; margin-right: auto; }
}
@media (min-width: 1200px) {
  .max-w-lg-10 { max-width: 10%; margin-left: auto; margin-right: auto; }
  .max-w-lg-20 { max-width: 20%; margin-left: auto; margin-right: auto; }
  .max-w-lg-25 { max-width: 25%; margin-left: auto; margin-right: auto; }
  .max-w-lg-30 { max-width: 30%; margin-left: auto; margin-right: auto; }
  .max-w-lg-40 { max-width: 40%; margin-left: auto; margin-right: auto; }
  .max-w-lg-50 { max-width: 50%; margin-left: auto; margin-right: auto; }
  .max-w-lg-60 { max-width: 60%; margin-left: auto; margin-right: auto; }
  .max-w-lg-70 { max-width: 70%; margin-left: auto; margin-right: auto; }
  .max-w-lg-75 { max-width: 75%; margin-left: auto; margin-right: auto; }
  .max-w-lg-80 { max-width: 80%; margin-left: auto; margin-right: auto; }
  .max-w-lg-90 { max-width: 90%; margin-left: auto; margin-right: auto; }
}
@media (min-width: 1536px) {
  .max-w-xl-10 { max-width: 10%; margin-left: auto; margin-right: auto; }
  .max-w-xl-20 { max-width: 20%; margin-left: auto; margin-right: auto; }
  .max-w-xl-25 { max-width: 25%; margin-left: auto; margin-right: auto; }
  .max-w-xl-30 { max-width: 30%; margin-left: auto; margin-right: auto; }
  .max-w-xl-40 { max-width: 40%; margin-left: auto; margin-right: auto; }
  .max-w-xl-50 { max-width: 50%; margin-left: auto; margin-right: auto; }
  .max-w-xl-60 { max-width: 60%; margin-left: auto; margin-right: auto; }
  .max-w-xl-70 { max-width: 70%; margin-left: auto; margin-right: auto; }
  .max-w-xl-75 { max-width: 75%; margin-left: auto; margin-right: auto; }
  .max-w-xl-80 { max-width: 80%; margin-left: auto; margin-right: auto; }
  .max-w-xl-90 { max-width: 90%; margin-left: auto; margin-right: auto; }
}


`;
