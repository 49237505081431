import Routes from './Routes';
// @ts-ignore
import { Router } from 'react-router-dom';

import history from 'config/history/history';
import { SnackbarProvider } from 'notistack';

const App = () => {
  window.onbeforeunload = () => {
    if (window.location.pathname !== '/' && window.location.pathname !== '/login' && window.location.pathname !== '/callback' && !window.location.pathname.includes('/user/activate')) 
      sessionStorage.setItem('pathname', window.location.pathname);
  };
  
  return (
    <div>
      <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <Router history={history}>
          <Routes />
        </Router>
      </SnackbarProvider>
    </div>
  )
}

export default App;