import { useState } from 'react';
import { IconButton, Input, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface SecurityCodeFieldProps {
  onChange: (value: string) => void;
  isWrongSecurityCode?: boolean;
}

export const SecurityCodeField = ({ onChange, isWrongSecurityCode }: SecurityCodeFieldProps) => {
  const [showSecurityCode, setShowSecurityCode] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
      {/*No wrap text label*/}
      <label style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{'Código de segurança'}</label>
      <Input
        style={{ width: '100%' }}
        type={showSecurityCode ? 'text' : 'password'}
        placeholder='Digite seu código de segurança'
        onChange={e => onChange(e.target.value)}
        error={isWrongSecurityCode}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={() => setShowSecurityCode(!showSecurityCode)}>
              {showSecurityCode ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </div>

  );
};