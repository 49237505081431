import React, { ReactElement } from 'react';
import { IconType } from 'react-icons';
import { FaCopy } from 'react-icons/fa';
import { ClipboardHelper } from '../../../helpers/ClipboardHelper';
import { Grid } from '@material-ui/core';

interface AttributeProps {
  active: boolean;
  name: string;
  value: string;
  icon: ReactElement<IconType>;
}

export const Attribute = ({ active, name, value, icon }: AttributeProps) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text/plain', name);
    e.dataTransfer.dropEffect = 'move';
  };

  const handleClipboardClick = async () => {
    await ClipboardHelper.copyToClipboard(name);
  };

  return (
    <Grid container className={active ? 'active' : ''} style={{alignItems: 'center'}} draggable={true} onDragStart={handleDragStart}>
      <Grid item md={1} style={{textAlign: 'left'}}>
        <h4>{icon}</h4>
      </Grid>
      <Grid item md={10}>
        <span style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
          <h4>{name}</h4>
          <p style={{margin: '0'}}>{value}</p>
        </span>
      </Grid>
      <Grid item md={1} style={{textAlign: 'right'}}>
        <h4><FaCopy style={{ cursor: 'pointer' }} onClick={handleClipboardClick} /></h4>
      </Grid>
    </Grid>
  );
};