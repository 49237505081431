import styled from 'styled-components';

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  'a': {
    color: theme.bgColors.primaryDefault
  }
}));

export const Container = styled('div')(({ theme }) => ({
  padding: '2rem 0.75rem',
  [theme.breakpoints.up('md')]: {
    padding: '2rem 0'
  }
}));

export const Wrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up('md')]: {
    maxWidth: 960
  },
  [theme.breakpoints.up('lg')]: {
  },
  [theme.breakpoints.up('xl')]: {
  },
  'p': {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%'
    },
    [theme.breakpoints.up('xl')]: {
    }
  }

}));

export const Box = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 0,
  width: '100%',
  display: 'block',
  margin: '2rem auto',
  [theme.breakpoints.up('md')]: {
    border: '1px solid #0C326F',
    borderRadius: '1rem',
    padding: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '1.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '2rem'
  },
  label: {
    display: 'block',
    fontWeight: '700'
  },
  '.MuiInputBase-root': {
    display: 'block'
  }

}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold'
}));