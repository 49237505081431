import { isDevelopment } from './environment';

export const ClipboardHelper = {
  copyToClipboard: async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      if (isDevelopment()) {
        console.error(error);
      }
    }
  },
  getFromClipboard: async () => {
    try {
      return await navigator.clipboard.readText();
    } catch (error) {
      if (isDevelopment()) {
        console.error(error);
      }
    }
  }
};