import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({ open, onClose, onConfirm }: ConfirmModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Redefinição do código de segurança</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Um e-mail será enviado para o endereço cadastrado com as instruções para redefinição do código de segurança.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} color='secondary' variant='outlined'>Cancelar</Button>
        <Button onClick={onConfirm} color='primary'>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
};