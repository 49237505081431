import styled from 'styled-components';

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  'a': {
    color: theme.bgColors.primaryDefault
  }
}));

export const Container = styled('div')(({ theme }) => ({
  padding: '2rem 0.75rem',
  // boxShadow: 'inset 0 0 5px 1px rgba(255,105,255,0.5)',
  [theme.breakpoints.up('md')]: {
    padding: '2rem 0'
  }
}));

export const Wrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up('md')]: {
    maxWidth: 960
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1200
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1360
  },
  'p': {
    [theme.breakpoints.up('md')]: {
      // maxWidth: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%'
    },
    [theme.breakpoints.up('xl')]: {
      // maxWidth: '50%',
    }
  }

}));

export const Box = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 0,
  width: '100%',
  display: 'block',
  margin: '1rem auto',
  [theme.breakpoints.up('md')]: {
    border: '1px solid #0C326F',
    borderRadius: '1rem',
    padding: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '1.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '2rem'
  },
  label: {
    display: 'block',
    fontWeight: '700'
  },
  '.MuiInputBase-root': {
    display: 'block'
  },
  '.MuiFormControl-root': {
    margin: '0 0 0.6rem',
  },
  '.MuiButtonBase-root': {
    display: 'block',
    width: '100%'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    thead: {
      backgroundColor: theme.bgColors.primaryPastel02,
      th: {
        padding: '0.5rem 0.25rem'
      }
    },
    tbody: {
      tr: {
        ':nth-child(even)': {
          backgroundColor: '#f3f3f3'
        },
        td: {
          padding: '0.875rem 0.25rem',
          fontSize: '0.875rem'
        },
        'td.flex': {
          display: 'flex',
          justifyContent: 'center',
          gap: '0.75rem',
          padding: '0.875rem 0.75rem'
        }
      }
    }
  }
}));

export const Operador = styled('div')(({ theme }) => ({
  'h3': {
    margin: 0
  },
  'p': {
    margin: '0.375rem 0 0 20px'
  },
  ':not(:last-child)': {
    marginBottom: '2rem'
  }
}));

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold'
}));