import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';
import { useAppTranslation } from '../../hooks';

interface ConfirmationModalProps {
  open: boolean,
  title: string | ReactElement,
  body: string | ReactElement,
  onClose: () => void,
  onConfirm: () => void,
}

export const ConfirmationModal = ({ open, title, body, onClose, onConfirm }: ConfirmationModalProps) => {
  const [t] = useAppTranslation('COMMONS');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} color='secondary' variant='outlined'>{t('CANCEL')}</Button>
        <Button onClick={onConfirm} color='primary'>{t('CONFIRM')}</Button>
      </DialogActions>
    </Dialog>
  );
};