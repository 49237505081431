import { baseApi } from './baseApi';
import { AuthenticationResponse } from '../../types/AuthenticationResponse';
import { AuthenticationRequest } from '../../types/AuthenticationRequest';

export const authenticationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    authenticateUser: build.mutation<AuthenticationResponse, AuthenticationRequest>({
      query: (body) => ({
        url: '/auth/authenticate',
        method: 'POST',
        body
      }),
      invalidatesTags: ['LocalUser']
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST'
      }),
      invalidatesTags: ['LocalUser']
    })
  }),
  overrideExisting: false,
});

export const { useAuthenticateUserMutation, useLogoutUserMutation } = authenticationEndpoints;
