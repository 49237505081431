import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Pageable } from '../types/Pageable';
import { defaultPaginationSize } from '../helpers/environment';
import { PaginationHookReturn } from '../types/PaginationHookReturn';
import { ModalVisibilityHookReturn } from '../types/ModalVisibilityHookReturn';
import { useLocation } from 'react-router-dom';
import { Role } from '../types/Role';
import { useAppSelector } from '../config/store/hooks';

/**
 * Custom hook to apply translation.
 * @param namespace the language applied namespace.
 * <br />
 * It will look up on language file (from /assets/lang) using given namespace and key.
 * The Translation Function (aka "t" function) then will search for the given key located inside the namespace.
 * @see ptBr.js
 */
export const useAppTranslation = (namespace: string = 'DEFAULT'): UseTranslationResponse<string> => useTranslation(namespace);

/**
 * Custom hook to manage pagination state.
 * @param pageNumber the current page number (zero index).
 * @param pageSize the page size.
 * @param sort the array of ordering to use.
 * @see Pageable
 */
export const usePagination = ({ pageNumber, pageSize, sort }: Partial<Pageable>): PaginationHookReturn => {
  const [pageable, setPageable] = useState<Pageable>({
    pageNumber: pageNumber ?? 0,
    pageSize: pageSize ?? defaultPaginationSize,
    sort: sort ?? []
  });

  const onPageChange = (newPage: number) => {
    setPageable({
      ...pageable,
      pageNumber: newPage
    });
  };

  return [pageable, onPageChange];
};

/**
 * Creates a hook for managing the visibility of a modal.
 *
 * @returns An object containing the current visibility state of the modal along with functions to open and close the modal.
 */
export const useModalVisibility = (): ModalVisibilityHookReturn => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const openModal = useCallback(() => setIsVisible(true), []);
  const closeModal = useCallback(() => setIsVisible(false), []);

  return { isVisible, openModal, closeModal };
};

/**
 * Returns an instance of URLSearchParams based on the current search query string.
 *
 * @param customUrl - Optional custom URL to use instead of the current search query string.
 * @returns {URLSearchParams} - URLSearchParams instance representing the search query string.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @see https://v5.reactrouter.com/web/example/query-parameters
 */
export const useQuery = (customUrl?: string): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(customUrl ?? search), [search, customUrl]);
};

/**
 * Returns the role of the user.
 *
 * @returns {Role} The role of the user.
 */
export const useRole = (): Role => {
  const localUser = useAppSelector(state => state.localUser.user);
  const federatedUserDetails = useAppSelector(state => state.user.details);

  return useMemo(() => {
    return localUser ? 'MASTER' : federatedUserDetails?.role ?? 'UNKNOWN';
  }, [federatedUserDetails?.role, localUser]);
};

/**
 * Returns whether the user is authenticated or not.
 *
 * @returns {Object} The authentication state of the user.
 * @property {boolean} isAuthenticated - Indicates whether the user is authenticated or not.
 */
export const useAuthentication = (): { isAuthenticated: boolean } => {
  const localUser = useAppSelector(state => state.localUser.user);
  const federatedUserDetails = useAppSelector(state => state.user.claims);

  return useMemo(() => {
    return {
      isAuthenticated: localUser != null || federatedUserDetails != null,
    };
  }, [federatedUserDetails, localUser]);
};