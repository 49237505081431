import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'

type DispatchFunc = () => AppDispatch;

/**
 * A typed custom hook extended from useDispatch.
 */
export const useAppDispatch: DispatchFunc = useDispatch;

/**
 * A typed custom hook extended from useSelector.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;