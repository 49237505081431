import { Button, Grid, Input } from '@material-ui/core';
import { useAppTranslation, useModalVisibility } from '../../../hooks';
import { useCreateUserMutation } from '../../../config/api/idpUserEndpoints';
import { useMemo, useState } from 'react';
import { IdentityProvider } from '../../../types/IdentityProvider';
import Navbar from '../../../components/Navbar/Index';
import { Box, Container, Wrapper } from '../../../assets/styles/defaultStyles';
import { AutocompleteIDPs } from './AutocompleteIDPs';
import { Actions } from '../styles';
import Footer from '../../../components/Footer/Index';
import { SecurityCodeModal } from '../../../components/SecurityCodeModal/SecurityCodeModal';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

interface NewIdpUserProps {
  dictionaryKey: string,
  listDictionaryKey: string,
  userRole: 'OPERATOR' | 'ADMIN'
}

export const NewIdpUser = ({ dictionaryKey, listDictionaryKey, userRole }: NewIdpUserProps) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useAppTranslation(dictionaryKey);
  const [createUser, createUserResult] = useCreateUserMutation();
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);
  const securityCodeModalState = useModalVisibility();
  
  const [newIdpUser, setNewIdpUser] = useState({
    name: '',
    email: '',
    confirmEmail: '',
    identityProvider: null as IdentityProvider | null
  });

  const handleSave = (securityCode: string) => {
    if (newIdpUser.email !== newIdpUser.confirmEmail || newIdpUser.identityProvider === null)
      return;

    createUser([{
      name: newIdpUser.name,
      email: newIdpUser.email,
      identityProvider: newIdpUser.identityProvider,
      role: userRole
    }, securityCode]).unwrap()
      .then(() => {
        securityCodeModalState.closeModal();
        enqueueSnackbar(t('USER_FORM:SUCCESS'), { variant: 'success', autoHideDuration: 3000 });
        if (userRole === 'OPERATOR') {
          history.push('/private/operators');
        }
        if (userRole === 'ADMIN') {
          history.push('/private/administrators');
        }
      })
      .catch((e) => {
        if (e.status === 403) {
          setIsWrongSecurityCode(true);
        } else {
          setIsWrongSecurityCode(false);
          if (e.status === 409)
            enqueueSnackbar(t('USER_FORM:EMAIL_ALREADY_EXISTS'), { variant: 'error', autoHideDuration: 3000 });
          else if (e.status === 406)
            enqueueSnackbar(t('USER_FORM:ERROR_LIMIT_OPERATORS'), { variant: 'error', autoHideDuration: 3000 });
          else if (userRole === 'OPERATOR')
            enqueueSnackbar(t('USER_FORM:ERROR_GENERIC_OPERATOR'), { variant: 'error', autoHideDuration: 3000 });
          else if (userRole === 'ADMIN')
            enqueueSnackbar(t('USER_FORM:ERROR_GENERIC_ADMIN'), { variant: 'error', autoHideDuration: 3000 });
          else
            enqueueSnackbar(t('USER_FORM:ERROR_GENERIC'), { variant: 'error', autoHideDuration: 3000 });
        }
      });
  };

  const canSubmit = () => {
    return newIdpUser.name !== '' && newIdpUser.email !== '' && newIdpUser.confirmEmail !== '' && newIdpUser.identityProvider !== null && !invalidEmail;
  };

  const invalidEmail = useMemo(() => {
    return newIdpUser.email !== newIdpUser.confirmEmail;
  }, [newIdpUser.email, newIdpUser.confirmEmail]);

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>
            <div style={{ textAlign: 'center' }}>
              <h1>{t(`${listDictionaryKey}:TITLE`)}</h1>
              <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto' }}
                 dangerouslySetInnerHTML={{ __html: t(`${listDictionaryKey}:DESCRIPTION`) }}></p>
            </div>

            <Box>
              <Grid container={true} spacing={3}>
                <Grid item xs={12}>
                  <AutocompleteIDPs
                    filterIdpLimitReached={userRole === 'OPERATOR'}
                    handleChangeIdp={(idp) => setNewIdpUser({ ...newIdpUser, identityProvider: idp })} />
                </Grid>

                <Grid item xs={12}>
                  <label>{t('USER_FORM:NAME')}<span style={{ color: 'red' }}>*</span></label>
                  <Input type='text' placeholder={t('USER_FORM:PLACEHOLDER_NAME')} onChange={e => setNewIdpUser({
                    ...newIdpUser,
                    name: e.target.value
                  })} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>{t('USER_FORM:EMAIL')}<span style={{ color: 'red' }}>*</span></label>
                  <Input type='email' placeholder={t('USER_FORM:PLACEHOLDER_EMAIL')}
                         onChange={e => {
                           setNewIdpUser({ ...newIdpUser, email: e.target.value });
                           createUserResult.reset();
                         }} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>{t('USER_FORM:CONFIRM_EMAIL')}<span style={{ color: 'red' }}>*</span></label>
                  <Input type='email' placeholder={t('USER_FORM:PLACEHOLDER_CONFIRM_EMAIL')}
                         onChange={e => {
                           setNewIdpUser({ ...newIdpUser, confirmEmail: e.target.value });
                           createUserResult.reset();
                         }} />
                </Grid>
              </Grid>
            </Box>

            <Actions>
              <div>
                <Button size='large' variant='outlined' color='secondary'
                        onClick={() => history.goBack()}>Voltar</Button>
              </div>
              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                {invalidEmail && newIdpUser.confirmEmail !== '' &&
                  <p style={{ color: 'red' }}>Os e-mails não coincidem</p>}
                {createUserResult.isLoading && <div style={{ color: '#aaa' }}>Solicitação enviada, aguarde...</div>}
                <div style={{ textAlign: 'end' }}><Button type='submit' size='large'
                                                          disabled={!canSubmit() || createUserResult.isLoading || createUserResult.isError}
                                                          onClick={() => securityCodeModalState.openModal()}>Salvar</Button>
                </div>
              </div>
            </Actions>
          </div>
          <SecurityCodeModal
            open={securityCodeModalState.isVisible}
            handleConfirm={handleSave}
            isWrongSecurityCode={isWrongSecurityCode}
            handleClose={() => {
              securityCodeModalState.closeModal();
              setIsWrongSecurityCode(false);
            }}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};