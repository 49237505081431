import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Box } from '../styles';
import { Autocomplete } from '@material-ui/lab';
import { useAppTranslation } from '../../../hooks';
import { IdpUser } from '../../../types/IdpUser';
import { IdentityProvider } from '../../../types/IdentityProvider';
import { IdpUserStatus } from '../../../types/IdpUserStatus';

interface FilterProps {
  filterState: Partial<IdpUser>;
  identityProviders: IdentityProvider[];
  handleFilterChange: (field: keyof Partial<IdpUser>, value: string | IdentityProvider | null) => void;
  handleFilterApplyClick: () => void;
  handleFilterRemoveClick: () => void;
}

export const Filters = ({
                          filterState,
                          identityProviders,
                          handleFilterChange,
                          handleFilterApplyClick,
                          handleFilterRemoveClick,
                        }: FilterProps) => {
  const [t] = useAppTranslation('FILTER');

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete renderInput={params => <TextField {...params} label={t('FILTER_BY_IDP_LABEL')} />}
                      value={filterState.identityProvider ?? null}
                      options={identityProviders} getOptionLabel={option => option.commonName}
                      noOptionsText={t('COMMONS:NO_OPTIONS')}
                      onChange={(_, value) => handleFilterChange('identityProvider', value)} />
      </FormControl>

      <FormControl fullWidth>
        <TextField label={t('FILTER_BY_NAME_LABEL')} value={filterState.name ?? ''}
                   onChange={e => handleFilterChange('name', e.target.value)} />
      </FormControl>

      <FormControl fullWidth>
        <TextField label={t('FILTER_BY_EMAIL_LABEL')} value={filterState.email ?? ''}
                   onChange={e => handleFilterChange('email', e.target.value)} />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: '1.8rem' }}>
        <InputLabel>{t('FILTER_BY_STATUS_LABEL')}</InputLabel>
        <Select onChange={e => handleFilterChange('status', e.target.value as IdpUserStatus)}
                value={filterState.status == null ? 'ALL' : filterState.status}>
          <MenuItem value={'ACTIVE'}>{t('FILTER_BY_STATUS_ACTIVE')}</MenuItem>
          <MenuItem value={'INACTIVE'}>{t('FILTER_BY_STATUS_INACTIVE')}</MenuItem>
          <MenuItem value={'PENDING_VALIDATION'}>{t('FILTER_BY_STATUS_PENDING')}</MenuItem>
          <MenuItem value={'PENDING_ACTIVATION'}>{t('FILTER_BY_STATUS_PENDING_ACTIVATION')}</MenuItem>
          <MenuItem value={'ALL'}>{t('FILTER_BY_STATUS_ALL')}</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={2} style={{ marginTop: '1.5rem' }}>
        <Grid item xs={12} md={6}><Button variant='outlined'
                                          onClick={handleFilterRemoveClick}>{t('CLEAN_FILTERS')}</Button></Grid>
        <Grid container item xs={12} md={6} justifyContent='flex-end'><Button
          onClick={handleFilterApplyClick}>{t('DO_FILTERS')}</Button></Grid>
      </Grid>
    </Box>
  );
};