import styled from 'styled-components';

export const Container = styled('div')(({ theme }) => ({
  padding: '2rem 0.75rem',
  [theme.breakpoints.up('md')]: {
    padding: '2rem 0',
  },
}));

export const Wrapper = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '100%',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up('md')]: {
    maxWidth: 960,
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 1200,
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 1360,
  },
  'p': {
    [theme.breakpoints.up('md')]: {
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up('xl')]: {
    },
  },

}));

export const Box = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 0,
  width: '100%',
  display: 'block',
  margin: '1rem auto',
  [theme.breakpoints.up('md')]: {
    border: '1px solid #0C326F',
    borderRadius: '1rem',
    padding: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    padding: '1.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    padding: '2rem',
  },
  label: {
    display: 'block',
    fontWeight: '700',
  },
  '.MuiInputBase-root': {
    display: 'block',
  },
  '.MuiFormControl-root': {
    margin: '0.2rem 0',
  },
  '.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    width: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    thead: {
      backgroundColor: theme.bgColors.primaryPastel02,
      th: {
        padding: '0.5rem 0.25rem',
      },
    },
    tbody: {
      tr: {
        ':nth-child(even)': {
          backgroundColor: '#f3f3f3',
        },
        td: {
          padding: '0.875rem 0.25rem',
          fontSize: '0.875rem',
        },
        'td.flex': {
          display: 'flex',
          justifyContent: 'center',
          gap: '0.75rem',
          padding: '0.875rem 0.75rem',
        },
      },
    },
  },
}));

export const Operador = styled('div')(() => ({
  'h3': {
    margin: 0,
  },
  'p': {
    margin: '0.375rem 0 0 20px',
  },
  ':not(:last-child)': {
    marginBottom: '2rem',
  },
}));