import { Box } from '../../../assets/styles/defaultStyles';
import { Button, Grid, Input } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { useCreateApiCredentialMutation } from '../../../config/api/apiCredentialEndpoints';
import { useSnackbar } from 'notistack';
import { useAppTranslation } from '../../../hooks';

export const Form = () => {
  const [t] = useAppTranslation('API_KEY_FORM');
  const [applicationUrl, setApplicationUrl] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [createApiCredential] = useCreateApiCredentialMutation();
  const { enqueueSnackbar } = useSnackbar();

  let isApplicationUrlEmpty = useMemo(() => applicationUrl === '', [applicationUrl]);
  let isApplicationUrlInvalid = useMemo(() => {
    try {
      new URL(applicationUrl);
    } catch (_) {
      return true;
    }
    return false;
  }, [applicationUrl]);

  const handleAddClick = () => {
    if (isApplicationUrlEmpty) {
      enqueueSnackbar(t('REQUIRED_URL_MESSAGE'), { variant: 'error', autoHideDuration: 3000 });
      return;
    }
    if (isApplicationUrlInvalid) {
      enqueueSnackbar(t('INVALID_URL_MESSAGE'), { variant: 'error', autoHideDuration: 3000 });
      return;
    }
    
    createApiCredential({ applicationUrl, description }).unwrap().then(() => {
      setApplicationUrl('');
      setDescription('');
      enqueueSnackbar(t('CREATE_SUCCESS_MESSAGE'), { variant: 'success', autoHideDuration: 3000 });
    }).catch(() => {
      enqueueSnackbar(t('CREATE_ERROR_MESSAGE'), { variant: 'error' });
    });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <label>{t('APPLICATION_URL_LABEL')}</label>
          <Input required={true} placeholder={t('APPLICATION_URL_PLACEHOLDER')} value={applicationUrl}
                 onChange={e => setApplicationUrl(e.target.value)} />
        </Grid>
        <Grid item xs={5}>
          <label>{t('DESCRIPTION_LABEL')}</label>
          <Input placeholder={t('DESCRIPTION_PLACEHOLDER')} multiline={true} maxRows={4} value={description}
                 onChange={e => setDescription(e.target.value)} />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleAddClick} disabled={applicationUrl === ''}>{t('COMMONS:ADD')}</Button>
        </Grid>
      </Grid>
    </Box>
  );
};