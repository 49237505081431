import { MappingState } from '../../../types/MappingState';
import { StaticAttribute } from '../../../types/StaticAttribute';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useAppTranslation } from '../../../hooks';
import { Table } from '../styles';
import { useMemo } from 'react';
import { IdpAttributes } from '../../../types/IdpUser';

interface ReviewModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  staticAttributes: StaticAttribute[];
  attributesFromIdp: IdpAttributes;
  mapping: MappingState;
}

export const ReviewModal = ({ open, handleClose, handleConfirm, staticAttributes, attributesFromIdp, mapping }: ReviewModalProps) => {
  const orderedStaticAttributes = useMemo(() => {
    const usedAttributes = staticAttributes.filter((attribute) => (mapping.staticAttributes[attribute.id].value.length > 0));
    const notUsedAttributes = staticAttributes.filter((attribute) => (mapping.staticAttributes[attribute.id].value.length === 0));
    return [...usedAttributes, ...notUsedAttributes];
  }, [staticAttributes, mapping.staticAttributes]);

  const [t] = useAppTranslation('STATIC_ATTRIBUTE');
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'lg'}
      PaperProps={{ style: { borderRadius: '0.875rem' } }}
    >
      <DialogTitle style={{ paddingTop: '0.3rem', paddingBottom: '0' }}>
        <h2>Revise o mapeamento</h2>
        <hr style={{ backgroundColor: '#0C326F', height: '3px', marginBottom: '0.5rem' }} />
      </DialogTitle>
      <DialogContent>
        <Table>
          <thead>
              <tr>
                <th align={'right'}>Atributo ICPEdu</th>
                <th align={'left'}>Valor</th>
                <th style={{ borderLeft: '1px solid #fff' }}>Atributo(s) do IDP</th>
              </tr>
          </thead>
              <tbody>
                {orderedStaticAttributes.map((attribute) => (
                  <tr key={attribute.id} style={mapping.staticAttributes[attribute.id].value.length === 0 ? {color: '#aaa'} : {}}>
                    <td align={'right'}>{t(attribute.name)}</td>
                    <td align={'left'}>{mapping.staticAttributes[attribute.id].value.map((value) => (attributesFromIdp[value])).join(' ')}</td>
                    <td
                      style={{ borderLeft: '1px solid #fff' }}>{mapping.staticAttributes[attribute.id].value.map((value) => (
                      <p style={{ margin: '0' }}>{value}</p>))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
        </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={handleClose} color='default' variant='outlined'>{t('COMMONS:CANCEL')}</Button>
          <Button onClick={handleConfirm}>{t('COMMONS:CONFIRM')}</Button>
        </DialogActions>
    </Dialog>
  );
};