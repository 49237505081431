import './Badge.css';
import { ReactNode } from 'react';

export type Color = 'primary' | 'secondary' | 'default' | 'error' | 'warning' | 'info' | 'success';

interface BadgeProps {
  color?: Color;
  children: ReactNode;
}

const colors = {
  primary: '#007bff',
  secondary: '#6c757d',
  default: '#007bff',
  error: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  success: '#28a745',
};

export const Badge = ({ color, children }: BadgeProps) => {
  const hexColor = colors[color ?? 'default'];

  return (
    <span className='badge' style={{
      color: 'white',
      backgroundColor: hexColor,
    }}>{children}</span>
  );
};