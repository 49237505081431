import { Redirect, useParams } from 'react-router-dom';
import { OauthHelper } from '../../helpers/OauthHelper';

const ActivateUser = () => {
  const { code } = useParams<{ code: string | undefined }>();

  if (code === undefined || code === '')
    return <Redirect to={'/'} />;

  const url = OauthHelper.getAuthorizeUrl(code);
  window.location.replace(url);

  return (
    <></>
  );
};

export default ActivateUser;