import Footer from 'components/Footer/Index';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';


import { Container, Header, Wrapper } from './styles';
import { OauthHelper } from '../../helpers/OauthHelper';
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { resetUser } from '../../config/store/slices/user.slice';
import { useAppSelector } from '../../config/store/hooks';
import { resetLocalUser } from '../../config/store/slices/local.user.slice';
import { ExternalURLsHelper } from '../../helpers/ExternalURLsHelper';
import { FaExternalLinkAlt } from 'react-icons/fa';


const Home = () => {
  const dispatch = useDispatch();
  const userClaims = useAppSelector(state => state.user.claims);

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (token && userClaims === null) {
      try {
        const user: any = jwtDecode(token);

        if ('idp_id' in user) {
          window.location.replace(`/callback#id_token=${token}`);
        }
      } catch (error) {
        console.warn('Invalid token');
      }
    }
  });

  if (userClaims != null) {
    return <Redirect push to='/private/mapper' />;
  }

  const handleLoginClick = () => {
    const url = OauthHelper.getAuthorizeUrl();
    dispatch(resetLocalUser({}));
    dispatch(resetUser());

    window.location.replace(url);
  };

  return (
    <>
      <Header>
        <Button variant='outlined' href={ExternalURLsHelper.getAjudaRNPURL()} target='_blank' rel="noreferrer">Ajuda RNP</Button>
      </Header>
      <Container>
        <Wrapper>
          <div style={{
            textAlign: 'center',
            padding: '2rem 0',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '80.7vh',
            justifyContent: 'space-between'
          }}>
            <div></div>

            <div>
              <h1>Mapeador de Atributos - ICPEdu</h1>
              <p style={{ fontSize: '1.25rem' }}>O Mapeador de Atributos é um serviço para associar e distribuir
                informações fornecidas pelas instituições para utilização nos serviços ICPEdu.</p>
              <p
                style={{ marginTop: '3rem', display: 'flex', flexDirection: 'column', maxWidth: '35%', gap: '0.5rem' }}>
                <Button size='large' onClick={handleLoginClick} style={{ fontWeight: 'bold', fontSize: '1.35rem' }}>
                  Acesse pela sua instituição*
                </Button>
                <span style={{ fontSize: '0.8rem', alignSelf: 'flex-end' }}>*Requer acesso como operador</span>
              </p>
            </div>

            <div>
              <hr style={{ backgroundColor: 'rgba(19, 81, 180, 0.5)', height: '4px', margin: '4rem 0' }} />

              <h2 style={{ margin: '4rem 0 2rem 0' }}>Serviços ICPEdu</h2>

              <Grid container={true} spacing={3} style={{ marginBottom: '4rem', maxWidth: '60%', margin: 'auto' }}>
                <Grid item xs={12} md={4}>
                  <a href={ExternalURLsHelper.getAssinadorURL()} target='_blank' rel="noreferrer"
                     style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                    <h3>Assinador</h3><FaExternalLinkAlt />
                  </a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href={ExternalURLsHelper.getSaecURL()} target='_blank' rel="noreferrer"
                     style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                    <h3>Certificado Pessoal</h3><FaExternalLinkAlt />
                  </a>
                </Grid>
                <Grid item xs={12} md={4}>
                  <a href={ExternalURLsHelper.getRepositorioURL()} target='_blank' rel="noreferrer"
                     style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
                    <h3>Repositório</h3><FaExternalLinkAlt />
                  </a>
                </Grid>
              </Grid>
            </div>
          </div>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
