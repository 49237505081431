import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { ThemeProvider as SCThemeProvider } from 'styled-components';
import GlobalStyles from 'assets/styles/global';
import muiTheme from 'assets/styles/theme';

import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import 'assets/styles/css/styles.css';

import store from 'config/store/store';

import 'config/i18n';
import 'config/yup';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ReduxProvider store={store}>
        <MuiThemeProvider theme={muiTheme}>
          <SCThemeProvider theme={muiTheme}>
            <GlobalStyles />
              <App />
          </SCThemeProvider>
        </MuiThemeProvider>
      </ReduxProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
