import thunk from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';

import { isDevelopment } from 'helpers/environment';

import rootReducer from './root.reducer';
import { baseApi } from '../api/baseApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, baseApi.middleware]
});

/**
 * Webpack module replacer for dev environment.
 * It's sort of a helper for Redux's debugging and development.
 */
if (isDevelopment() && module.hot) {
  module.hot.accept('config/store/root.reducer', () => {
    const newRootReducer = require('config/store/root.reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
