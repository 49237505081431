import { baseApi } from './baseApi';
import { PageableHelper } from '../../helpers/PageableHelper';
import { Page } from '../../types/Page';
import { Configuration } from '../../types/Configuration';
import { Pageable } from '../../types/Pageable';

export const configurationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getConfigurations: build.query<Page<Configuration>, { term: string, pageable: Pageable }>({
      query: ({ term, pageable }) =>
        `/configuration?term=${encodeURIComponent(term)}${PageableHelper.queryParamsFromPageable(pageable)}`,
      transformResponse: (response: any): Page<Configuration> => {
        return {
          empty: response.empty,
          first: response.first,
          last: response.last,
          number: response.number,
          numberOfElements: response.numberOfElements,
          size: response.size,
          totalElements: response.totalElements,
          totalPages: response.totalPages,
          content: response.content
        };
      },
      providesTags: ['Configuration']
    }),
    getConfigurationById: build.query<Configuration, string>({
      query: (id) => `/configuration/${id}`,
      //multiple unused parameters https://github.com/microsoft/TypeScript/issues/24856
      providesTags: (_, __, id) => ['Configuration', { type: 'Configuration', id }]
    }),
    updateConfiguration: build.mutation<void, [Omit<Configuration, 'name'>, string]>({
      query: ([configuration, securityCode]) => ({
        url: `/configuration/${configuration.id}`,
        method: 'PUT',
        body: {
          ...configuration,
          securityCode
        }
      }),
      invalidatesTags: ['Configuration']
    })
  }),
  overrideExisting: false,
});

export const {
  useGetConfigurationsQuery,
  useGetConfigurationByIdQuery,
  useUpdateConfigurationMutation
} = configurationEndpoints;