import { baseApi } from './baseApi';
import { StaticAttribute } from '../../types/StaticAttribute';
import { Mapper } from '../../types/Mapper';
import { MappedAttributes } from '../../types/MappedAttributes';
import { defaultLargeCacheTime } from '../../helpers/environment';

export const mapperEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getStaticAttributes: build.query<StaticAttribute[], void>({
      query: () => `/mapper/static-attributes`,
      providesTags: ['StaticAttribute'],
      keepUnusedDataFor: defaultLargeCacheTime,
    }),
    createMapping: build.mutation<void, ([Mapper, String])>({
      query: ([mapper, securityCode]) => ({
        url: '/mapper',
        method: 'POST',
        body: {
          ...mapper,
          securityCode,
        }
      }),
      invalidatesTags: ['Mapper']
    }),
    getMappedAttributes: build.query<MappedAttributes, void>({
      query: () => `/mapper/mapped-attributes`,
      providesTags: ['Mapper'],
    }),
  })
});

export const { useGetStaticAttributesQuery, useCreateMappingMutation, useGetMappedAttributesQuery } = mapperEndpoints;