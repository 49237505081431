import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ReactElement } from 'react';

interface ModalFeedbackUserVerificationProps {
  open: boolean,
  hasError?: boolean
  content: ReactElement
}

const ModalFeedbackUserVerification = ({ open, hasError, content }: ModalFeedbackUserVerificationProps) => {

  const RedirectToHome = () => {
    window.location.href = '/';
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Ativação de usuário</DialogTitle>
      <DialogContent style={{ textAlign: 'center', margin: '0.5rem auto' }}>
        {content}
      </DialogContent>
      <DialogActions>
        {hasError === true
          && <Button onClick={RedirectToHome} color='default'>Voltar a tela inicial</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ModalFeedbackUserVerification;