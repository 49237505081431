import { combineReducers } from '@reduxjs/toolkit';

import localUser from 'config/store/slices/local.user.slice';
import user from 'config/store/slices/user.slice';
import { baseApi } from '../api/baseApi';

const rootReducer = combineReducers({
  localUser,
  user,
  [baseApi.reducerPath]: baseApi.reducer
});

export default rootReducer;
