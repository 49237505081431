const developmentEnv: boolean =
  process?.env?.REACT_APP_ENV === 'development' ||
  process?.env?.NODE_ENV === 'development';

/**
 * Defines whether environment set is "development".
 * 
 * @param run an optional argument - should be a function - to run in dev environment
 */
export const isDevelopment = (run?: any): boolean => {
  if (developmentEnv && run) run();
  return developmentEnv;
};

export const recaptchaEnabled: boolean =
  process.env.REACT_APP_RECAPTCHA_DISABLED !== 'true';

export const appVersion: string | undefined = process?.env?.REACT_APP_VERSION;
export const apiUrl: string | undefined = process?.env?.REACT_APP_API_URL;
export const defaultPaginationSize: number = 10;
export const defaultLargeCacheTime: number = 60 * 60 * 24; // 1 day