import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { SecurityCodeField } from './SecurityCodeField';
import { Link } from 'react-router-dom';
import { useRole } from '../../hooks';

interface SecurityCodeModalProps {
  open: boolean;
  handleConfirm: (securityCode: string) => void;
  handleClose: () => void;
  isWrongSecurityCode: boolean;
}

export const SecurityCodeModal = ({
                                    open,
                                    handleConfirm,
                                    isWrongSecurityCode,
                                    handleClose
                                  }: SecurityCodeModalProps) => {
  const role = useRole();
  
  const [securityCode, setSecurityCode] = useState('');
  if (securityCode !== '' && !open) setSecurityCode('');
  
  return (
    <Dialog open={open} onClose={handleClose} onKeyDown={e => e.key === 'Enter'
      && securityCode !== '' && handleConfirm(securityCode)}>
      <DialogTitle>{'Código de segurança'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{'Está é uma operação protegida que exige confirmação com código de segurança.'}</DialogContentText>
        {role !== 'MASTER' &&
          <DialogContentText>Vocẽ pode redefinir o código de segurança em <Link to='/private/my-account'> minha
            conta.</Link></DialogContentText>}
        <SecurityCodeField onChange={setSecurityCode} isWrongSecurityCode={isWrongSecurityCode} />
        {isWrongSecurityCode &&
          <p style={{ color: 'red', fontSize: '0.8rem', textAlign: 'end' }}>Código de segurança inválido</p>}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <Button onClick={handleClose} color='secondary' variant='outlined'>Cancelar</Button>
        <Button onClick={() => handleConfirm(securityCode)} color='primary'
                disabled={securityCode === ''}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
};