import Navbar from '../../components/Navbar/Index';
import { Box, Container, Wrapper } from '../../assets/styles/defaultStyles';
import { Button, Grid, Input } from '@material-ui/core';
import { useAppTranslation, useModalVisibility } from '../../hooks';
import { ConfirmModal } from './ConfirmModal';
import { useSnackbar } from 'notistack';
import { useResetValidationCodeMutation } from '../../config/api/idpUserEndpoints';
import { useAppSelector } from '../../config/store/hooks';
import Footer from '../../components/Footer/Index';

export const MyAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const confirmationModal = useModalVisibility();
  const [resetValidationCode] = useResetValidationCodeMutation();
  const userDetails = useAppSelector(state => state.user.details);
  const [t] = useAppTranslation();
  
  const handleConfirm = () => {
    resetValidationCode().unwrap()
      .then(() => {
        confirmationModal.closeModal();
        enqueueSnackbar('Solicitação de redefinição de código de segurança enviada com sucesso.', {
          variant: 'success',
          autoHideDuration: 3000
        });
      })
      .catch(() => {
        enqueueSnackbar('Erro ao solicitar redefinição de código de segurança.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      });
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>Minha conta</h1>
              <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto' }}>
                Visualize suas informações de cadastro.
              </p>
            </div>

            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h3>Informações pessoais</h3>
                  <hr style={{ backgroundColor: '#0C326F', height: '3px' }} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>Nome</label>
                  <Input value={userDetails?.name} readOnly />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>E-mail</label>
                  <Input value={userDetails?.email} readOnly />
                </Grid>
                <Grid item xs={12} md={10}>
                  <label>Instituição</label>
                  <Input value={userDetails?.identityProvider.commonName} readOnly />
                </Grid>
                <Grid item xs={12} md={2}>
                  <label>Nível de usuário</label>
                  <Input value={t(`ROLES:${userDetails?.role}`)} readOnly />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h3>Código de segurança</h3>
                  <hr style={{ backgroundColor: '#0C326F', height: '3px', marginTop: '1rem' }} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <p style={{ fontSize: '1.1rem', maxWidth: '100%', margin: '0' }}>
                    Em caso de impossibilidade de acesso ao código de segurança, é possível redefini-lo clicando no
                    botão abaixo.
                  </p>
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                  <Button size='large'
                          onClick={() => confirmationModal.openModal()}>
                    Redefinir código de segurança
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
          <ConfirmModal
            open={confirmationModal.isVisible}
            onClose={confirmationModal.closeModal}
            onConfirm={handleConfirm}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  )
    ;
};