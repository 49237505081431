import { NewIdpUser } from './components/NewIdpUser';

const NewAdministrator = () => {
  return (
    <NewIdpUser dictionaryKey={'ADMIN_NEW'}
                listDictionaryKey={'ADMIN_LIST'}
                userRole={'ADMIN'} />
  );
};

export default NewAdministrator;
