export const OauthHelper = {
  /**
   * Generates the authorization URL for OAuth2.0 authentication.
   *
   * @param {string} [state] - The optional state parameter.
   * @returns {string} The authorization URL.
   */
  getAuthorizeUrl: (state?: string): string => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
    const scope = process.env.REACT_APP_SCOPE;
    const url = process.env.REACT_APP_AUTHORIZE_URL;
    const nonce = OauthHelper.generateNonce();
    const responseType = 'id_token';
    const responseMode = 'query';

    sessionStorage.setItem('nonce', nonce);
    if (state) sessionStorage.setItem('state', state);

    return `${url}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&nonce=${nonce}${state ? `&state=${state}` : ''}`;
  },
  /**
   * Generates a random nonce.
   *
   * @returns {string} The generated nonce.
   */
  generateNonce: (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  },
  generateCodeVerifier: () => {
    const arrayLength = 64;

    const array = new Uint8Array(arrayLength);
    crypto.getRandomValues(array);

    return btoa(String.fromCodePoint(...array));
  },
};