import Navbar from 'components/Navbar/Index';
import Footer from 'components/Footer/Index';
import { Button, Grid, Input } from '@material-ui/core';

import { Box, Container, Operador, Wrapper } from './styles';
import { FaUser } from 'react-icons/fa';
import {
  useCreateIdentityProviderContactInfoMutation,
  useGetAllOperatorsQuery,
  useGetMyIdentityProviderQuery,
} from '../../config/api/identityProviderEndpoints';
import { Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useModalVisibility } from '../../hooks';
import { SecurityCodeModal } from '../../components/SecurityCodeModal/SecurityCodeModal';
import { useSnackbar } from 'notistack';

const MyInstitution = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: operators,
    isSuccess: isSuccessGetOperators,
    isFetching: isFetchingGetOperators
  } = useGetAllOperatorsQuery();
  const {
    data: myIdentityProvider,
    isSuccess: isSuccessGetMyIdentityProvider,
    isFetching: isFetchingGetMyIdentityProvider
  } = useGetMyIdentityProviderQuery();
  const [createIdentityProviderContactInfo, createIdentityProviderContactInfoResult] = useCreateIdentityProviderContactInfoMutation();

  const [editMode, setEditMode] = useState(false);
  const [department, setDepartment] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (myIdentityProvider !== undefined) {
      myIdentityProvider.department && setDepartment(myIdentityProvider.department);
      myIdentityProvider.phone && setPhone(myIdentityProvider.phone);
      myIdentityProvider.email && setEmail(myIdentityProvider.email);
      if (myIdentityProvider.department === null && myIdentityProvider.phone === null && myIdentityProvider.email === null) setEditMode(true);
    }
  }, [myIdentityProvider]);

  useEffect(() => {
    if (createIdentityProviderContactInfoResult.isSuccess)
      setEditMode(false);
  }, [createIdentityProviderContactInfoResult.isSuccess]);

  /* Security Code modal */
  const securityCodeModalState = useModalVisibility();
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);

  const handleCancel = () => {
    createIdentityProviderContactInfoResult.reset();
    setEditMode(false);
    setDepartment(myIdentityProvider?.department || '');
    setPhone(myIdentityProvider?.phone || '');
    setEmail(myIdentityProvider?.email || '');
  };
  
  const handleSave = () => {
    if (myIdentityProvider !== undefined) {
      securityCodeModalState.openModal();
    }
  };

  const handleEnterEdit = () => {
    createIdentityProviderContactInfoResult.reset();
    setEditMode(true);
  };

  const canSubmit = () => {
    const phoneNumbers = phone.replace(/\D/g, '');  
    
    return department !== '' && email !== '' &&
      (phoneNumbers.length === 10 || phoneNumbers.length === 11) &&
      department.length <= 255 && email.length <= 255 &&
      !createIdentityProviderContactInfoResult.isLoading;
  };

  const phoneMask = () => (phone?.length >= 6 && phone?.[5] === '9' ? '(99) 99999-9999' : '(99) 9999-9999');

  const handleConfirmSecurityCode = (securityCode: string) => {
    if (myIdentityProvider !== undefined)
      createIdentityProviderContactInfo([{
        ...myIdentityProvider,
        department: department,
        phone: phone,
        email: email
      }, securityCode]).unwrap()
        .then(() => {
          setIsWrongSecurityCode(false);
          securityCodeModalState.closeModal();
          enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success', autoHideDuration: 3000 });
        })
        .catch((e) => {
          if (e.status === 403) {
            setIsWrongSecurityCode(true);
          } else {
            setIsWrongSecurityCode(false);
            if (e.status === 401)
              enqueueSnackbar('Erro na validação dos dados', { variant: 'error', autoHideDuration: 3000 });
            else
              enqueueSnackbar('Erro ao atualizar dados', { variant: 'error', autoHideDuration: 3000 });
          }
        });
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>Minha instituição</h1>

              {isFetchingGetMyIdentityProvider && <Skeleton variant='text' width='100%' height='5rem' />}
              {!isFetchingGetMyIdentityProvider && isSuccessGetMyIdentityProvider &&
                <h2>{myIdentityProvider?.commonName}</h2>
              }
              
              <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto' }}>Veja os operadores ativos
                cadastrados e mantenha as informações de contato atualizadas para que os usuários possam entrar em
                contato, solucionar dúvidas e solicitações junto à instituição.</p>
            </div>

            <Grid container spacing={3}>

              <Grid item xs={12} md={4}>
                <h2 style={{ textAlign: 'center' }}>Operadores ativos</h2>
                <Box>
                  {isFetchingGetOperators &&
                    <>
                      <Skeleton variant='text' width='100%' height='5rem' />
                      <Skeleton variant='text' width='100%' height='5rem' />
                      <Skeleton variant='text' width='100%' height='5rem' />
                    </>
                  }
                  {!isFetchingGetOperators && isSuccessGetOperators &&
                    operators.map((operator) => (
                      <Operador key={operator.id}>
                        <h3><FaUser size={14} color='#1351B4' /> {operator.name}</h3>
                        <p>{operator.email}</p>
                      </Operador>
                    ))
                  }
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <h2 style={{ textAlign: 'center' }}>Informações da instituição</h2>

                <Box>
                  {isFetchingGetMyIdentityProvider &&
                    <>
                      <Skeleton variant='text' width='100%' height='5rem' />
                      <Skeleton variant='text' width='100%' height='5rem' />
                      <Skeleton variant='text' width='100%' height='5rem' />
                    </>
                  }

                  {!isFetchingGetMyIdentityProvider && isSuccessGetMyIdentityProvider &&
                    <>
                      <Grid container={true} spacing={3}>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <label>Departamento<span style={{ color: 'red' }}>*</span></label>
                            <span style={{ color: 'red', fontSize: '0.7rem', position: 'relative', bottom: '0.8rem' }}>* Campos obrigatórios</span>
                          </div>
                          <Input type='text' placeholder='Departamento responsável' value={department}
                                 readOnly={!editMode}
                                 onChange={e => setDepartment(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <label>Telefone<span style={{ color: 'red' }}>*</span></label>
                          <InputMask mask={phoneMask()}
                                     maskChar={null}
                                     minLength={11}
                                     value={phone} onChange={e => setPhone(e.target.value)} readOnly={!editMode}>
                            {(inputProps: any) => <Input type='tel'
                                                         placeholder='Telefone de contato' {...inputProps} />}
                          </InputMask>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <label>E-mail<span style={{ color: 'red' }}>*</span></label>
                          <Input type='email' placeholder='Informe um e-mail de contato' value={email}
                                 readOnly={!editMode}
                                 onChange={e => setEmail(e.target.value)} />
                        </Grid>
                      </Grid>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '2rem',
                        alignItems: 'center'
                      }}>
                        <div>
                          {!editMode && <Button size='large' variant='outlined' color='primary'
                                                onClick={handleEnterEdit}>Editar</Button>}
                          {editMode && <Button size='large' variant='outlined' color='secondary'
                                               onClick={handleCancel}>Cancelar</Button>}
                        </div>
                        <div>
                          <div style={{ display: 'flex', gap: '1rem', width: '100%', alignItems: 'center' }}>
                            {createIdentityProviderContactInfoResult.isLoading &&
                              <div style={{ color: '#aaa' }}>Dados enviados, aguarde...</div>}
                            {editMode && <Button size='large' color={'primary'} onClick={handleSave}
                                                 disabled={!canSubmit()}>Salvar</Button>}
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </Box>
              </Grid>
            </Grid>
          </div>
          <SecurityCodeModal
            open={securityCodeModalState.isVisible}
            handleConfirm={handleConfirmSecurityCode}
            isWrongSecurityCode={isWrongSecurityCode}
            handleClose={() => {
              securityCodeModalState.closeModal();
              setIsWrongSecurityCode(false);
            }}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default MyInstitution;
