import { UserClaims } from '../../../types/UserClaims';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdpUser } from '../../../types/IdpUser';

export const USER_REDUCER = 'user';

interface UserState {
  claims: UserClaims | null;
  details: IdpUser | null;
}

const userInitialState: UserState = {
  claims: null,
  details: null,
};

const user = createSlice({
  name: USER_REDUCER,
  initialState: userInitialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.details = action.payload.details;
      state.claims = action.payload.claims;
    },
    resetUser: (state) => {
      state.details = null;
      state.claims = null;
    },
  },
});

export const { setUser, resetUser } = user.actions;
export default user.reducer;