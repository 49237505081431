const ApiEncoderMethods = {
  /**
   * Encodes a nested object into a serialized string for use in a HTTP query.
   *
   * @param {any} obj - The object to be encoded.
   * @param {string} key - The key for the nested object.
   * @param {string} [prefix] - The prefix to be used for encoding.
   * @returns {string} - The encoded string.
   */
  encodeNestedObject: (obj: any, key: string, prefix?: string): string =>
    ApiQueryHelper.serializeHttpParams(obj, prefix ? `${prefix}.${key}` : key),

  /**
   * Encodes a primitive value for query parameter
   *
   * @param {any} value - The value to be encoded
   * @param {string} key - The key for the query parameter
   * @param {string} [prefix] - The optional prefix for the query parameter
   * @returns {string} The encoded query parameter string
   */
  encodePrimitiveValue: (value: any, key: string, prefix?: string): string => {
    const encodedValue = encodeURIComponent(value.toString());
    return prefix ? `${prefix}.${key}=${encodedValue}` : `${key}=${encodedValue}`;
  },
};

export const ApiQueryHelper = {
  /**
   * Converts an object to a query string.
   *
   * @param {Object} obj - The object to convert to HTTP parameters.
   * @param {string} nestedKeyPrefix - The current nested property key.
   * @returns {string} The query string representation of the object.
   */
  serializeHttpParams: (obj: { [key: string]: any }, nestedKeyPrefix?: string): string => {
    const queryStringParts: string[] = [];

    for (const [key, value] of Object.entries(obj)) {
      if (value === undefined || value === null) continue;

      const encodedKey = encodeURIComponent(key);
      const queryStringPart = typeof value === 'object'
        ? ApiEncoderMethods.encodeNestedObject(value, encodedKey, nestedKeyPrefix)
        : ApiEncoderMethods.encodePrimitiveValue(value, encodedKey, nestedKeyPrefix);
      queryStringParts.push(queryStringPart);
    }

    return queryStringParts.length > 0 ? `${nestedKeyPrefix == null ? '?' : ''}${queryStringParts.join('&')}` : '';
  }
};