import { baseApi } from './baseApi';
import { ApiCredential } from '../../types/ApiCredential';
import { Pageable } from '../../types/Pageable';
import { Page } from '../../types/Page';

export const apiCredentialEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllApiCredentials: build.query<Page<ApiCredential>, Pageable>({
      query: (pageable) => ({ url: `api-credentials`, params: pageable }),
      providesTags: ['ApiCredential'],
    }),
    createApiCredential: build.mutation<void, Pick<ApiCredential, 'applicationUrl' | 'description'>>({
      query: (apiKey) => ({ url: `api-credentials`, method: 'POST', body: apiKey }),
      invalidatesTags: ['ApiCredential'],
    }),
    deleteApiCredential: build.mutation<void, [string, string]>({
      query: ([apiKey, securityCode]) => ({
        url: `api-credentials/${apiKey}`,
        method: 'DELETE',
        body: securityCode
      }),
      invalidatesTags: ['ApiCredential'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllApiCredentialsQuery,
  useCreateApiCredentialMutation,
  useDeleteApiCredentialMutation,
} = apiCredentialEndpoints;