import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { LocalUser } from '../../../types/LocalUser';

export const LOCAL_USER_REDUCER = 'localUser';

interface LocalUserState {
  user: LocalUser | null;
}

const localUserInitialState: LocalUserState = {
  user: null,
};


const localUser: Slice<LocalUserState> = createSlice({
  name: LOCAL_USER_REDUCER,
  initialState: localUserInitialState,
  reducers: {
    setLocalUser: (state, action: PayloadAction<LocalUser>) => {
      state.user = action.payload;
    },
    resetLocalUser: (state) => {
      state.user = null;
    },
  },
});

export const { setLocalUser, resetLocalUser } = localUser.actions;
export default localUser.reducer;
