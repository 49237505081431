import { NewIdpUser } from './components/NewIdpUser';

const NewOperator = () => {
  return (
    <NewIdpUser dictionaryKey={'OPERATOR_NEW'}
                listDictionaryKey={'OPERATOR_LIST'}
                userRole={'OPERATOR'} />
  );
};

export default NewOperator;
