import { createStyles, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Pageable } from '../../../types/Pageable';
import { useGetAllIdentityProvidersQuery } from '../../../config/api/identityProviderEndpoints';
import { useMemo } from 'react';
import { IdentityProvider } from '../../../types/IdentityProvider';
import { useAppTranslation } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    inlineFlex: {
      '& .MuiAutocomplete-inputRoot': {
        display: 'inline-flex !important',
        width: '100%'
      }
    }
  })
);

interface AutocompleteIDPsProps {
  filterIdpLimitReached: boolean,
  handleChangeIdp: (idp: IdentityProvider | null) => void
}

export const AutocompleteIDPs = ({ filterIdpLimitReached, handleChangeIdp }: AutocompleteIDPsProps) => {
  const [t] = useAppTranslation();

  const defaultInitialPageableForIdentityProvider: Pageable = {
    pageNumber: 0,
    pageSize: 5000,
    sort: [{ field: 'name', order: 'asc' }]
  };
  const { data: identityProviderData } = useGetAllIdentityProvidersQuery(defaultInitialPageableForIdentityProvider);
  const identityProviders = useMemo(() => {
    if (identityProviderData?.content) {
      const identityProviders: IdentityProvider[] = identityProviderData?.content;
      return [...identityProviders].sort((a, b) => {
        if (a.limitOperatorsReached === b.limitOperatorsReached) {
          return a.commonName.localeCompare(b.commonName);
        }

        return a.limitOperatorsReached ? 1 : -1;
      });
    }
    return [];
  }, [identityProviderData?.content]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <label>{t('USER_FORM:IDENTITY_PROVIDER')}<span style={{ color: 'red' }}>*</span></label>
        <span style={{
          color: 'red',
          fontSize: '0.7rem',
          position: 'relative',
          bottom: '0.8rem'
        }}>* Campos obrigatórios</span>
      </div>
      <Autocomplete
        renderInput={params => <TextField {...params} placeholder={'Selecione uma instituição'} />}
        className={useStyles().inlineFlex}
        noOptionsText={t('COMMONS:NO_OPTIONS')}
        getOptionLabel={option => option.commonName}
        options={identityProviders}
        groupBy={filterIdpLimitReached ? option => option.limitOperatorsReached ? 'Limite de operadores atingido' : '' : undefined}
        getOptionDisabled={filterIdpLimitReached ? option => option.limitOperatorsReached : undefined}
        onChange={(_, value) => handleChangeIdp(value)}
        openOnFocus fullWidth />
    </>
  );
};