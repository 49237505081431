import Footer from 'components/Footer/Index';
import { Button, Input } from '@material-ui/core';
import { FaLock, FaUser } from 'react-icons/fa';


import { Container, Field, Wrapper } from './styles';
import { Redirect } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useAuthenticateUserMutation } from '../../config/api/authenticationEndpoints';
import { loginSchema } from './loginSchema';
import { ValidationError } from 'yup';
import { ErrorMessage } from './components/ErrorMessage';
import { useDispatch } from 'react-redux';
import { setLocalUser } from '../../config/store/slices/local.user.slice';
import jwt_decode from 'jwt-decode';
import { LocalUser } from '../../types/LocalUser';
import { useAppSelector } from '../../config/store/hooks';
import { useAppTranslation } from '../../hooks';
import { BeatLoader } from 'react-spinners';
import { resetUser } from '../../config/store/slices/user.slice';


const Login = () => {
  const [t] = useAppTranslation('LOCAL_LOGIN');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticateUser, {
    data: authenticationResponse,
    isSuccess,
    isLoading,
    isError,
    error
  }] = useAuthenticateUserMutation();
  const [errors, setErrors] = useState<{ [key: string]: string | null }>({ email: null, password: null });
  const { user: currentUser } = useAppSelector(state => state.localUser);
  const { claims: federatedUser } = useAppSelector(state => state.user);
  const dispatch = useDispatch();
  const keyEnter = 'Enter';

  const token = sessionStorage.getItem('token');

  const errorData = useMemo(() => {
    if (error) {
      return (error as any).data.errors[0];
    }
  }, [error]);

  useEffect(() => {
    if (token && currentUser === null && federatedUser === null) {
      let user: any = jwt_decode(token);
      if (!('idp_id' in user)) {
        user = user as LocalUser;

        user.attributes = JSON.parse(sessionStorage.getItem('attributes') ?? '[]');

        dispatch(setLocalUser(user));
      }
    }

    if (isSuccess) {
      if (!authenticationResponse) {
        console.error('No authentication response');
        return;
      }

      if (!authenticationResponse?.access_token) {
        console.error('No access token found in authentication response');
        return;
      }

      const user = jwt_decode<LocalUser>(authenticationResponse?.access_token || '');
      dispatch(resetUser());
      dispatch(setLocalUser(user));

      sessionStorage.setItem('token', authenticationResponse?.access_token || '');
    }
  }, [authenticationResponse, authenticationResponse?.access_token, currentUser, dispatch, federatedUser, isSuccess, token]);

  const handleSubmit = () => {
    try {
      loginSchema.validateSync({ email, password }, { abortEarly: false });

      setErrors({ email: null, password: null });

      authenticateUser({ email, password });
    } catch (e) {
      if (!(e instanceof ValidationError)) {
        throw e;
      }

      const newErrors: { [key: string]: string | null } = { email: null, password: null };

      e.inner.forEach(error => {
        if (error.path) {
          newErrors[error.path] = error.message;
        }
      });

      setErrors(newErrors);
    }
  };

  const hasAnyError = (field: string) => errors[field] !== null && errors[field] !== undefined;

  const hasHttpStatus = (status: number) => {
    return error && 'status' in error && error?.status === status;
  };

  if ((token && currentUser) || isSuccess) {
    const pathname = sessionStorage.getItem('pathname');

    if (pathname) {
      return <Redirect push to={pathname} />;
    }
    
    return <Redirect push to={'/private/configurations'} />;
  }

  return (
    <>
      <Container>
        <Wrapper>
          <div style={{
            textAlign: 'center',
            padding: '2rem 0',
            minHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }} onKeyDown={e => e.key === keyEnter && handleSubmit()}>

            <h1>{t('TITLE')}</h1>

            <Field>
              <FaUser />
              <Input name='email' placeholder={t('FIELD_USERNAME')} type='email' value={email}
                     onChange={e => setEmail(e.target.value)} error={hasAnyError('email')}
                     autoFocus />
            </Field>
            <ErrorMessage error={errors['email']} />
            <Field>
              <FaLock />
              <Input name='password' placeholder={t('FIELD_PASSWORD')} type='password' value={password}
                     onChange={e => setPassword(e.target.value)}
                     error={hasAnyError('password')} />
            </Field>
            <ErrorMessage error={errors['password']} />

            <div
              style={{ marginTop: '3rem', marginBottom: '3rem', display: 'flex', gap: '1rem', justifyContent: 'center' }}>
              <Button variant='outlined' size='large' href='/'>{t('COMMONS:BACK')}</Button>
              {isLoading ? <Button size='large' disabled><BeatLoader /></Button> :
                <Button size='large' onClick={handleSubmit}>{t('BTN_FORM_LOGIN')}</Button>}
            </div>

            {isError && hasHttpStatus(403) && <ErrorMessage error={'ERROR:NotAuthorizedException'} />}
            {isError && hasHttpStatus(401) && errorData === 'Invalid credentials.' &&
              <ErrorMessage error={'ERROR:NotAuthorizedException'} />}
            {isError && hasHttpStatus(401) && errorData === 'Blocked' &&
              <ErrorMessage error={'ERROR:BlockedException'} />}
          </div>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Login;
