import { baseApi } from './baseApi';
import { Page } from '../../types/Page';
import { IdpUser } from '../../types/IdpUser';
import { Pageable } from '../../types/Pageable';
import { ApiQueryHelper } from '../../helpers/ApiQueryHelper';
import { PageableHelper } from '../../helpers/PageableHelper';
import { NewSecurityCode } from '../../types/NewSecurityCode';

export const idpUserEndpoints = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllUsers: builder.query<Page<IdpUser>, [Partial<IdpUser>, Pageable]>({
      query: ([user, pageable]) => ({
        url: `/users${ApiQueryHelper.serializeHttpParams(user)}&${PageableHelper.queryParamsFromPageable(pageable, false)}`
      }),
      providesTags: ['User']
    }),
    inactiveUser: builder.mutation<void, [string, string]>({
      query: ([operatorId, securityCode]) => ({
        url: `/users/${operatorId}/inactive`,
        method: 'PATCH',
        body: {
          securityCode,
        }
      }),
      invalidatesTags: ['User', 'IdentityProvider'],
    }),
    activeUser: builder.mutation<void, [string, string]>({
      query: ([operatorId, securityCode]) => ({
        url: `/users/${operatorId}/active`,
        method: 'PATCH', 
        body: {
          securityCode,
        }
      }),
      invalidatesTags: ['User', 'IdentityProvider'],
    }),
    createUser: builder.mutation<void, [Pick<IdpUser, 'name' | 'email' | 'identityProvider' | 'role'>, string]>({
      query: ([user, securityCode]) => ({
        url: `/users`,
        method: 'POST',
        body: {
          ...user,
          securityCode,
        }
      }),
      invalidatesTags: ['User', 'IdentityProvider']
    }),
    createValidationCode: builder.mutation<void, NewSecurityCode>({
      query: createSecurityCode => ({
        url: `/users/validation-code`,
        method: 'PATCH',
        body: {
          ...createSecurityCode
        }
      }),
      invalidatesTags: ['User']
    }),
    getMe: builder.query<IdpUser, string | void>({
      query: (token) => ({
        url: `/users/me`,
        headers: typeof token === 'string' ? { Authorization: `Bearer ${token}` } : {},
      })
    }),
    resendValidationCode: builder.mutation<void, [string, string]>({
      query: ([userId, securityCode]) => ({
        url: `/users/${userId}/resend-validation-code`,
        method: 'PATCH',
        body: {
          securityCode,
        }
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<void, [string, string]>({
      query: ([userId, securityCode]) => ({
        url: `/users/${userId}`,
        method: 'DELETE',
        body: {
          securityCode,
        }
      }),
      invalidatesTags: ['User', 'IdentityProvider'],
    }),
    resetValidationCode: builder.mutation<void, void>({
      query: () => ({
        url: `/users/validation-code/reset`,
        method: 'PATCH'
      }),
      invalidatesTags: ['User']
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/users/logout',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    })
  }),
  overrideExisting: false
});

export const {
  useGetAllUsersQuery,
  useInactiveUserMutation,
  useActiveUserMutation,
  useCreateUserMutation,
  useCreateValidationCodeMutation,
  useGetMeQuery,
  useResendValidationCodeMutation,
  useDeleteUserMutation,
  useResetValidationCodeMutation,
  useLogoutMutation,
} = idpUserEndpoints;