import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
  useGetConfigurationByIdQuery,
  useUpdateConfigurationMutation
} from '../../../../../config/api/configurationEndpoints';
import Navbar from 'components/Navbar/Index';
import Footer from 'components/Footer/Index';
import { Button, Grid, Input } from '@material-ui/core';


import { Box, Container, Wrapper } from '../../../../../assets/styles/defaultStyles';
import { SyncLoader } from 'react-spinners';
import React, { useState } from 'react';
import { useAppTranslation, useModalVisibility } from '../../../../../hooks';
import { Skeleton } from '@material-ui/lab';
import { SecurityCodeModal } from '../../../../../components/SecurityCodeModal/SecurityCodeModal';
import { useSnackbar } from 'notistack';

export const EditConfiguration = () => {
  const history = useHistory();
  const [t] = useAppTranslation('CONFIGURATION_EDIT');
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [newValue, setNewValue] = useState<string | null>(null);
  const { data: configuration, isSuccess, isFetching, isError, error } = useGetConfigurationByIdQuery(id);
  const [updateConfiguration, updateResult] = useUpdateConfigurationMutation();

  /* Security Code modal */
  const securityCodeModalState = useModalVisibility();
  const [isWrongSecurityCode, setIsWrongSecurityCode] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleClick = () => {
    securityCodeModalState.openModal();
  }

  const handleConfirmSecurityCode = (securityCode: string) => {
    if (configuration && newValue !== '') {
      updateConfiguration([{ id: configuration.id, value: newValue ?? configuration.value }, securityCode])
        .unwrap()
        .then(() => {
          setIsWrongSecurityCode(false);
          securityCodeModalState.closeModal();
          enqueueSnackbar(t('UPDATE_SUCCESS_MESSAGE'), { variant: 'success', autoHideDuration: 3000 });
        })
        .catch((e) => {
          if (e.status === 403) setIsWrongSecurityCode(true);
          else enqueueSnackbar(t('UPDATE_ERROR_MESSAGE'), { variant: 'error', autoHideDuration: 3000 });
        });
    }
  };

  if (updateResult.isSuccess) {
    return <Redirect to='/private/configurations' />;
  }

  const isReadyToPresent = !isFetching && isSuccess && configuration !== undefined;

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>

            <div style={{ textAlign: 'center' }}>
              <h1>{t('TITLE')}</h1>
              <p style={{ fontSize: '1.25rem' }}>{t('SUB_TITLE')}</p>
            </div>


            <Box>
              {isReadyToPresent ?
                <Grid container={true} spacing={3}>
                <Grid item xs={12}>
                  <label>{t('FIELD_ID')}</label>
                  <Input disabled={true} placeholder={t('FIELD_ID_PLACEHOLDER')} value={configuration?.id} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <label>{t('FIELD_NAME')}</label>
                  <Input disabled={true} placeholder={t('FIELD_NAME_PLACEHOLDER')} value={configuration?.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <label>{t('FIELD_VALUE')}</label>
                  <Input required={true} placeholder={t('FIELD_VALUE_PLACEHOLDER')}
                         value={newValue ?? configuration?.value}
                         disabled={updateResult.isLoading} onChange={handleChange} />
                </Grid>
                </Grid>
                :
                <Skeleton variant='rect' height={150} />
              }
            </Box>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1.5rem' }}>
              <Button size='large' color='secondary' variant='outlined'
                      onClick={() => history.goBack()}>{t('COMMONS:BACK')}</Button>
              <Button size='large' disabled={updateResult.isLoading}
                                                      onClick={handleClick}>{updateResult.isLoading ?
                <SyncLoader /> : t('COMMONS:SAVE')}</Button>
            </div>

            {isError && error && <div style={{ textAlign: 'center' }}><p style={{ color: 'red' }}>{error}</p></div>}

          </div>

          <SecurityCodeModal
            open={securityCodeModalState.isVisible}
            handleConfirm={handleConfirmSecurityCode}
            isWrongSecurityCode={isWrongSecurityCode}
            handleClose={() => {
              securityCodeModalState.closeModal();
              setIsWrongSecurityCode(false);
            }}
          />
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};