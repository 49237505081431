import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { useEffect } from 'react';
import { UseMutationStateResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';

interface RequestModalProps {
  open: boolean;
  handleClose: () => void;
  //TODO: verificar um tipo melhor para o mutation
  requestMutation: UseMutationStateResult<any, any>;
}

export const RequestModal = ({ open, handleClose, requestMutation }: RequestModalProps) => {
  useEffect(() => {
    if (!requestMutation.isLoading) {
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [requestMutation.isLoading, handleClose]);

  const modalContent = () => {
    if (requestMutation.isSuccess) {
      return (
        <>
          <FaCheckCircle color='#268744' size={100} />
          <h2>Mapeamento realizado com sucesso!</h2>
        </>
      );
    } else if (requestMutation.isError && 'status' in requestMutation.error && requestMutation.error.status !== 403) {
      return (
        <>
          <FaExclamationCircle color='#E60000' size={100} />
          <h2>Ocorreu um erro ao realizar o mapeamento, tente novamente mais tarde.</h2>
        </>);
    } else return (
      <>
        <CircularProgress size={50} style={{ margin: 25 }} />
        <h2>Mapeamento enviado, aguarde...</h2>
      </>);
  };
  
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogContent style={{ textAlign: 'center' }}>
        {modalContent()}
      </DialogContent>
    </Dialog>
  );
};