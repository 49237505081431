import styled from 'styled-components';

export const Header = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  'a': {
    color: theme.bgColors.primaryDefault,
  },
}));

export const Container = styled('div')(({theme}) => ({
  padding: '2rem 0.75rem',
  // boxShadow: 'inset 0 0 5px 1px rgba(255,105,255,0.5)',
  [theme.breakpoints.up("md")]: {
    padding: '2rem 0',
  },
}));

export const Wrapper = styled('div')(({theme}) => ({
  margin: '0 auto',
  maxWidth: '100%',
  // boxShadow: 'inset 0 0 5px 1px rgba(255,255,105,0.5)',
  color: theme.bgColors.primaryDefault,
  [theme.breakpoints.up("md")]: {
    maxWidth: 960,
    // boxShadow: 'inset 0 0 5px 1px rgba(105,255,255,0.5)',
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1200,
    // boxShadow: 'inset 0 0 5px 1px rgba(255,105,105,0.5)',
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: 1360,
    // boxShadow: 'inset 0 0 5px 1px rgba(105,255,105,0.5)',
  },
  'p': {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up("md")]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: '66%',
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: '50%',
    },
  },
  '.MuiButton-containedSizeLarge': {
    fontSize: '1.25rem',
    padding: '1.25rem 3rem',
    borderRadius: '0.75rem',
  },
}));
