import Navbar from '../../../components/Navbar/Index';
import { Button } from '@material-ui/core';
import { useAppTranslation } from '../../../hooks';
import { Container, Wrapper } from '../styles';
import Footer from '../../../components/Footer/Index';
import UsersVisualization from '../Index';
import {
  useActiveUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useInactiveUserMutation,
} from '../../../config/api/idpUserEndpoints';
import { Link } from 'react-router-dom';

const Operators = () => {
  const [t] = useAppTranslation('OPERATOR_LIST');

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <div style={{ padding: '2rem 0' }}>
            <div style={{ textAlign: 'center' }}>
              <h1>{t('TITLE')}</h1>
              <p style={{ fontSize: '1.25rem', marginLeft: 'auto', marginRight: 'auto' }}
                 dangerouslySetInnerHTML={{ __html: t('DESCRIPTION') }}></p>
              <div style={{ margin: '2rem 0 3rem 0' }}><Link to={`/private/operator/new`}><Button
                size='large'>{t('ADD_OPERATOR_TITLE')}</Button></Link></div>
            </div>

            <UsersVisualization
              useGetAllUsersQuery={useGetAllUsersQuery}
              useActiveUserMutation={useActiveUserMutation}
              useInactiveUserMutation={useInactiveUserMutation}
              useDeleteUserMutation={useDeleteUserMutation}
              userRole='OPERATOR'
              messages={{
                'SUBMIT_ERROR': t('SUBMIT_ERROR'),
                'INACTIVE_SUCCESS': t('INACTIVE_SUCCESS'),
                'ACTIVE_SUCCESS': t('ACTIVE_SUCCESS')
              }} />
          </div>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Operators;