import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from 'assets/lang';
import { isDevelopment } from 'helpers/environment';

/* Reference
 * https://github.com/i18next/react-i18next/blob/master/example/locize/src/i18n.js
 */
i18n.use(initReactI18next).init({
  lng: 'ptBr',
  fallbackLng: 'ptBr',
  debug: isDevelopment(),
  resources,
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged editorSaved'
  },
}).then(() => {
  if(isDevelopment()) console.log("Successfully initialized i18n");
});

export default i18n;
