import { useAppTranslation } from '../../../hooks';
import { FaEnvelope, FaUser, FaUserSlash } from 'react-icons/fa';
import { IdpUser } from '../../../types/IdpUser';
import { Badge, Color } from './Badge';
import { Tooltip } from '@material-ui/core';
import { IdpUserStatus } from '../../../types/IdpUserStatus';

interface UsersTableProps {
  requestSuccess: boolean;
  users: IdpUser[];
  handleActiveUser: (user: IdpUser) => void;
  handleInactiveUser: (user: IdpUser) => void;
  handleDeleteUser: (user: IdpUser) => void;
  handleResendActivationEmail: (user: IdpUser) => void;
  canActiveUser: (user: IdpUser) => boolean;
  canInactiveUser: (user: IdpUser) => boolean;
  canResendActivationEmail: (user: IdpUser) => boolean;
  canDeleteUser: (user: IdpUser) => boolean;
}

export const UsersTable = ({
                             requestSuccess,
                             users,
                             handleActiveUser,
                             handleInactiveUser,
                             handleResendActivationEmail,
                             handleDeleteUser,   
                             canActiveUser,
                             canInactiveUser,
                             canDeleteUser,
                             canResendActivationEmail,
                           }: UsersTableProps) => {
  const [t] = useAppTranslation('TABLE');

  const statusToColorMap = {
    'ACTIVE': 'success',
    'INACTIVE': 'error',
    'PENDING_ACTIVATION': 'warning',
    'PENDING_VALIDATION': 'warning',
  };

  const buildStatusBadge = (status: IdpUserStatus) => {
    const color = (statusToColorMap[status] || 'secondary') as Color;
    const statusMessageKey = status ? `STATUS_${status}` : 'STATUS_UNKNOWN';

    return <Badge color={color}>{t(statusMessageKey)}</Badge>;
  };

  return (
    <table>
      <thead>
      <tr>
        <th style={{ width: '35%' }}>{t('HEADER_NAME')}</th>
        <th>{t('HEADER_EMAIL')}</th>
        <th>{t('HEADER_INSTITUTION')}</th>
        <th>{t('HEADER_STATUS')}</th>
        <th>{t('HEADER_ACTIONS')}</th>
      </tr>
      </thead>
      <tbody>
      {requestSuccess && users.length > 0 && users.map(user => (
        <tr key={user.id}>
          <td style={{
            display: 'inline-block',
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}><Tooltip title={user.name}>{<span>{user.name}</span>}</Tooltip></td>
          <td>{user.email}</td>
          <td><Tooltip title={user.identityProvider.commonName}
                       placement='top'><span>{user.identityProvider.code}</span></Tooltip></td>
          <td>{buildStatusBadge(user.status)}</td>
          <td className='flex'>
            {/*TODO: verificar regra de negócio*/}
            {/*{canEditUser(user) &&*/}
            {/*  <Link title='Editar operador' to={`/private/operator/${user.id}`}><FaPen cursor='pointer' /></Link>}*/}
            {canActiveUser(user) &&
              <span title='Ativar' onClick={_ => handleActiveUser(user)}><FaUser cursor='pointer' /></span>}
            {canInactiveUser(user) &&
              <span title='Inativar' onClick={_ => handleInactiveUser(user)}><FaUserSlash cursor='pointer' /></span>}
            {canDeleteUser(user) &&
              <span title='Excluir' onClick={_ => handleDeleteUser(user)}><FaUserSlash color={'red'} cursor='pointer' /></span>}
            {canResendActivationEmail(user) &&
              <span title='Reenviar e-mail de ativação' onClick={_ => handleResendActivationEmail(user)}><FaEnvelope
                cursor='pointer' /></span>}
          </td>
        </tr>
      ))}
      {requestSuccess && users.length === 0 &&
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>{t('EMPTY')}</td>
        </tr>}
      {!requestSuccess &&
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>{t('ERROR')}</td>
        </tr>}
      </tbody>
    </table>
  );
};